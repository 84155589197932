import { Button, Dialog, DialogActions, DialogTitle, Divider, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CURRENT_BUILD } from "../../config/build_config";
import { setCurrentLocation } from "../../features/navigation/navigationSlice";
import { SELLER_PAGE_SETTINGS } from "../../providers/NavigationProvider";
import AWS from 'aws-sdk';
import Compressor from 'compressorjs';
import { selectLoggedUser } from "../../features/session/sessionSlice";
import axios from "axios";
import { generatePresignedS3Url, UPDATE_BUSINESS_COVER_IMAGE, UPDATE_BUSINESS_PROFILE_IMAGE } from "../../config/urls";


export default function SellerPageSettingSideBar() {
    const [showAlert, setShowAlert] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    const user = useSelector(selectLoggedUser);
    const ID = 'AKIAQ2KCF3STN6DPFLUA';
    const SECRET = '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';
    const BUCKET_NAME = 'saruwata';
    const s3 = new AWS.S3({
        accessKeyId: ID,
        secretAccessKey: SECRET
    });
    const [profileImage, setProfileImage] = useState("");
    const [coverImage, setCoverImage] = useState("");

    const [profileImageFile, setProfileImageFile] = useState(null);
    const [coverImageFile, setCoverImageFile] = useState(null);
    const theme=useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));



    const addimagesToview = (img: any, type: string) => {
        const reader: any = new FileReader();
        reader.onloadend = function () {
            //   dispatch(addImagesToBNewPost(reader.result));
            if (type == "profile") {

                setProfileImageFile(img);
                setProfileImage(reader.result);
            } else {

                setCoverImageFile(img);
                setCoverImage(reader.result);
            }
        };
        try {
            reader.readAsDataURL(img);


        } catch (error) {
            return;
        }


    };
    const showImage = (e: any, type: string) => {
        e.persist();
        const image = e.target.files[0];


        //20mb
        if (image.size > 20971520) {
            setShowAlert1(true);
        } else {
            if (image.type == "image/jpeg" || image.type == "image/png") {
                addimagesToview(image, type);
                // console.log("img to view>>" + img.name);
            } else {
                setShowAlert(true);
                e.target.value = null;
            }
        }

        e.target.value = null;
    };
    const openImagePicker = () => {
        document.getElementById("image-picker")?.click();
    };
    const openImagePicker1 = () => {
        document.getElementById("image-picker1")?.click();
    };

    const handleCompressedUpload = (image: any, index: number, folder: number) => {

        var quality = 1;
        if (image.size >= 10485760) {
            quality = 0.5;
        } else if (image.size > 1572864) {

            quality = 0.6;
        } else {

            quality = 0.7;
        }
        new Compressor(image, {
            quality: quality, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {

                uploadToS3(compressedResult, index, folder);
                // setCompressedFile(compressedResult)
            },
        });
    };

    var urlArray = [] as string[];
  const uploadToS3 = async (image: any, index: number, folder: number) => {

  

    const response = await axios.get(
      `${generatePresignedS3Url}?bucketName=${BUCKET_NAME}&key=${CURRENT_BUILD}/shopDetails/${folder}/${folder}_${index}.${image.name.split('.')[1]}`
    );

    const presignedUrl = response.data;

    axios.put(presignedUrl, image, {
      headers: {
        "Content-Type": image?.type,
        skipAuth: true
      },
    })
      .then((d) => {
        if (d.status == 200) {

          const fileName = presignedUrl.split("?")[0];
         
          
          if (index == 0) {
            setProfileImageFile(null);
            setProfileImage("");
            axios.put(UPDATE_BUSINESS_PROFILE_IMAGE, {
                "propileImage": fileName
            }).then(
                () => {


                }
            ).catch(e => console.log(e));
        }
        else if (index == 1) {
            setCoverImageFile(null);
            setCoverImage("");
            axios.put(UPDATE_BUSINESS_COVER_IMAGE, {
                "coverImage": fileName
            }).then(
                () => { }
            ).catch(e => console.log(e));
        }
         
          urlArray.push(fileName);


        
        }
      })
      .catch(e => {
        console.log(e);

      });



  }

    // const uploadToS3 = async (image: any, index: number, folder: number) => {

    //     // Setting up S3 upload parameters

    //     const params = {
    //         ACL: 'public-read',
    //         Bucket: BUCKET_NAME,
    //         Key: `${CURRENT_BUILD}/shopDetails/${folder}/${folder}_${index}.${image.name.split('.')[1]}`, // File name you want to save as in S3
    //         Body: image
    //     };


    //     const paramss = {
    //         ACL: 'public-read',
    //         Bucket: BUCKET_NAME,
    //         Key: `${CURRENT_BUILD}/shopImages/${folder}/${folder}_${index}.*`, // File name you want to save as in S3
    //         Body: image
    //     };


      
       

    //     // Uploading files to the bucket
    //     const ss = s3.upload(params).on('httpUploadProgress', function (evt) {

    //         //   setProgress(evt.total, evt.loaded);

    //     }).promise();

    //     ss.then((d: any) => {
    //         //   dispatch(addNewPostRawImages(d.Location));

    //         if (index == 0) {
    //             setProfileImageFile(null);
    //             setProfileImage("");
    //             axios.put(UPDATE_BUSINESS_PROFILE_IMAGE, {
    //                 "propileImage": d.Location
    //             }).then(
    //                 () => {


    //                 }
    //             ).catch(e => console.log(e));
    //         }
    //         else if (index == 1) {
    //             setCoverImageFile(null);
    //             setCoverImage("");
    //             axios.put(UPDATE_BUSINESS_COVER_IMAGE, {
    //                 "coverImage": d.Location
    //             }).then(
    //                 () => { }
    //             ).catch(e => console.log(e));
    //         }

    //     });

    // }
    const updateImages = () => {
        if (profileImageFile != null) {
            handleCompressedUpload(profileImageFile, 0, user.email);
        }

        if (coverImageFile != null) {

            handleCompressedUpload(coverImageFile, 1, user.email);

        }
    }

    return (
        <>
            <Grid container style={{ borderTop: "2px solid #0080FF", marginTop:(isTabletOrMobile?"0":"50px")  }}>
                <Grid item xs={12} md={12} lg={12}>
                    <div style={{ display: "flex", flexDirection: "column", padding: "10px 20px", marginTop: "10px" }}>
                        <div style={{ fontWeight: 600, fontSize: "16px" }}>Attach Company logo</div>
                        <div style={{ fontStyle: "italic", color: "#999999", fontSize: "14px" }}>Upload a logo image of size 400x280 pixels. Supported image file formats jpg, jpeg, png</div>

                        <div style={{ display: "flex", marginTop: "10px" }}>
                            <div style={{ marginRight: "15px" }}>
                                <img style={{ maxWidth: "100px", maxHeight: "70px", marginTop: "10px" }} src={(profileImage==""?"../../assets/images/image_blank_100x70.png":profileImage)} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <Button variant="contained" onClick={openImagePicker}>Upload</Button>
                                <Button variant="contained" onClick={() => setProfileImage("")}>Remove</Button>
                            </div>
                        </div>
                    </div>
                    <br />

                    <Divider variant="middle" />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <div style={{ display: "flex", flexDirection: "column", padding: "10px 20px" }}>
                        <div style={{ fontWeight: 600, fontSize: "16px" }}>Add Cover photo (for shop page)</div>
                        <div style={{ fontStyle: "italic", color: "#999999", fontSize: "14px" }}>Upload a logo image of size 904x335 pixels.Supported image file formats jpg, jpeg, png</div>

                        <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                            <div style={{ marginRight: "15px" }}>
                                <img style={{ maxWidth: "260px", maxHeight: "96px", marginTop: "10px" }} src={(coverImage==""?"../../assets/images/image_cover_blank_260x96.png":coverImage)} />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Button variant="contained" style={{ marginRight: "10px" }} onClick={openImagePicker1}>Upload</Button>
                                <Button variant="contained" onClick={() => setCoverImage("")}>Remove</Button>
                            </div>
                        </div>
                    </div>
                    <br />


                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <div style={{ padding: "10px 20px" }}>
                        <Button variant="contained" style={{ width: "100%" }} color="primary" onClick={updateImages}>Update Details</Button>

                    </div>
                </Grid>
            </Grid>
            {/* <img src={profileImage} />
            <img src={coverImage} /> */}
            <input
                type="file"
                name="imagePicker"
                onChange={(e) => showImage(e, "profile")}
                accept="image/*"
                id="image-picker"
                onSelect={(e: any) => e.preventDefault()}
                hidden
            />
            <input
                type="file"
                name="imagePicker"
                onChange={(e) => showImage(e, "cover")}
                accept="image/*"
                id="image-picker1"
                onSelect={(e: any) => e.preventDefault()}
                hidden
            />
            <Dialog open={showAlert} onClose={() => setShowAlert(false)}>
                <DialogTitle id="alert-dialog-title">
                    Please select JPG or PNG
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setShowAlert(false)} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showAlert1} onClose={() => setShowAlert1(false)}>
                <DialogTitle id="alert-dialog-title">
                    Maximum image size is 15MB
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setShowAlert1(false)}
                        color="primary"
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
import { createSlice } from "@reduxjs/toolkit";

const tree = [
  {
    "slug": "motors", "level": 1, "usageCount": 0, "id": 294, "name": "MOTORS",
    "sub_categories": [
      { "categoryLevel": 2, "id": 295, "name": "HEAVY VEHICLES" },
      { "categoryLevel": 2, "id": 296, "name": "VANS" },
      { "categoryLevel": 2, "id": 297, "name": "THREE WHEELERS" },
      { "categoryLevel": 2, "id": 298, "name": "AUTO PARTS & ACCESSORIES" },
      { "categoryLevel": 2, "id": 299, "name": "FOR RENT OR HIRE" },
      { "categoryLevel": 2, "id": 300, "name": "MOTORBIKES" },
      { "categoryLevel": 2, "id": 301, "name": "CARS" },
      { "categoryLevel": 2, "id": 302, "name": "AUTO SERVICES" },
      { "categoryLevel": 2, "id": 303, "name": "BUSES & LORRIES" },
      { "categoryLevel": 2, "id": 342, "name": "BICYCLE" }
    
    ]
  },

  {
    "slug": "mobiles and electronics", "level": 1, "usageCount": 0, "id": 304, "name": "MOBILES AND ELECTRONICS",
    "sub_categories": [
      { "categoryLevel": 2, "id": 305, "name": "ELECTRONIC HOME APPLIANCES" },
      { "categoryLevel": 2, "id": 306, "name": "AUDIO & SPEAKERS" },
      { "categoryLevel": 2, "id": 307, "name": "LAPTOP DESKTOP & TABLETS" },
      { "categoryLevel": 2, "id": 308, "name": "COMPUTER ACCESSORIES" },
      { "categoryLevel": 2, "id": 309, "name": "MOBILE ACCESSORIES" },
      { "categoryLevel": 2, "id": 310, "name": "MOBILE PHONES" },
      { "categoryLevel": 2, "id": 311, "name": "CAMERAS & CAMCORDERS" },
      { "categoryLevel": 2, "id": 312, "name": "ELECTRONIC SERVICES & REPAIRS" },
      { "categoryLevel": 2, "id": 313, "name": "OTHER ELECTRONICS" }]
  },
  {
    "slug": "property for rent", "level": 1, "usageCount": 0, "id": 314, "name": "PROPERTY FOR RENT",
    "sub_categories": [
      { "categoryLevel": 2, "id": 315, "name": "APARTMENTS FOR RENT" },
      { "categoryLevel": 2, "id": 316, "name": "HOUSES FOR RENT" },
      { "categoryLevel": 2, "id": 317, "name": "ROOMS FOR RENT" },
      { "categoryLevel": 2, "id": 318, "name": "COMMERCIAL PROPERTY FOR RENT" },
      { "categoryLevel": 2, "id": 319, "name": "SHORT TERM (DAILY)" },
      { "categoryLevel": 2, "id": 320, "name": "SHORT TERM (MONTHLY)" },
      { "categoryLevel": 2, "id": 321, "name": "LANDS FOR RENT" }]
  },
  { "slug": "property for sale", "level": 1, "usageCount": 0, "id": 322, "name": "PROPERTY FOR SALE", "sub_categories": [{ "categoryLevel": 2, "id": 323, "name": "COMMERCIAL PROPERTY FOR SALE" }, { "categoryLevel": 2, "id": 324, "name": "HOUSES & VILLAS FOR SALE" }, { "categoryLevel": 2, "id": 325, "name": "APARTMENTS FOR SALE" }, { "categoryLevel": 2, "id": 326, "name": "LANDS FOR SALE" }] },
  { "slug": "classifieds", "level": 1, "usageCount": 0, "id": 327, "name": "CLASSIFIEDS", "sub_categories": [{ "categoryLevel": 2, "id": 328, "name": "HOME & GARDEN" }, { "categoryLevel": 2, "id": 329, "name": "KIDS & BABY ITEMS" }, { "categoryLevel": 2, "id": 330, "name": "FASHION & BEAUTY" }, { "categoryLevel": 2, "id": 331, "name": "HOBBY & SPORTS" }, { "categoryLevel": 2, "id": 332, "name": "PETS & ANIMALS" }, { "categoryLevel": 2, "id": 333, "name": "BUSINESS & INDUSTRY" }, { "categoryLevel": 2, "id": 334, "name": "WANTED & LOOKING FOR" }, { "categoryLevel": 2, "id": 335, "name": "LOST & FOUND" }, { "categoryLevel": 2, "id": 336, "name": "SERVICES" }, { "categoryLevel": 2, "id": 337, "name": "AGRICULTURE" }, { "categoryLevel": 2, "id": 338, "name": "ESSENTIALS" }, { "categoryLevel": 2, "id": 339, "name": "TRAVEL & LEISURE" }, { "categoryLevel": 2, "id": 340, "name": "OTHER" }] },
  { "slug": "others", "level": 1, "usageCount": 0, "id": 341, "name": "OTHERS", "sub_categories": [] }];

export const slice = createSlice({
  name: "tags",
  initialState: {
    categories: []
  },

  reducers: {
    saveCategories: (state, action) => {
      state.categories = action.payload;
    }
  },
});

export const { saveCategories } = slice.actions;


export const getAllCategories = () => (dispatch: any) => {


  dispatch(saveCategories(tree));
  // axios.get(CATEGORIES_GET_ALL, {
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded',

  //   }
  // })
  //   .then(result => {

  //     if (result.status === 200) {
  //       if (result.data.length > 0) {
  //         // console.log(result.data);
  //         dispatch(saveCategories(result.data));


  //       }

  //     }


  //   })

}


export const selectCategories = (state: any) => state.tags.categories;

export default slice.reducer;
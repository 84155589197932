
export class Language {
    selectedLan: string;
    constructor(language: string) {
        this.selectedLan = language;
    }

    english = {

        DOWNLOAD_OUR_MOBILE_APP: 'Download Our Mobile App',

        //Main Nav
        ALL_ADS: 'All Ads',
        MOTORS: 'Motors',
        MOBILE_AND_ELECTRONICS: 'Mobile & Electronics',
        MOBILES_AND_ELECTRONICS: 'Mobile & Electronics',
        PROPERTY_FOR_SALE: 'Property For Sale',
        PROPERTY_FOR_RENT: 'Property For Rent',
        CLASSIFIEDS: 'Classifieds',
        OTHERS: 'Other',
        OTHER: 'Other',

        //Landing Page
        WELCOME_TO_SARUWATA: 'Welcome to saruwata.lk',
        THE_NEW_MARKETPLACE_FOR_MODERN_SRILANKANS: 'The new marketplace for modern Sri Lankans',
        SEARCH: 'Search',
        FIND_THE_BEST: 'Find the best deals on Vehicles, Property, Electronics and more. Choose from a wide range of high quality ADs from trusted sellers.',
        VIEW_ALL_ADS: 'View All Ads',
        CATEGORIES: 'Categories',
        BROWSE_THROUGH: 'Browse through some of our most popular categories',
        POST_YOUR_AD: 'Post Your Ad',
        JUST_IN_FEW: 'just in few clicks.',
        ADD_UPTO_10: 'Add up to 10 photos on each Ad',
        CHOOSE_FROM_A_WIDE: 'Choose from a wide range of categories',
        FILL_IN_AVAILABLE: 'Fill in available details only',
        PUBLISH_ADS_AUTOMATICALLY: 'Publish ads automatically without any delays',
        USE_DIFFERENT_CONTACT: 'Use different contact numbers on ads',
        BUY_AND_SELL_ON: 'Buy and Sell on Saruwata App',
        ITS_TIME_TO: 'It’s time to UPGRADE your life!',
        TAKE_CAREFUL_LOOK: 'Take a careful look around! Car that you used, used phone, a guitar that you never play, fancy chair or jewellery, books and fashion items - Are you ready to post your ads on ',
        GET_YOUR_APP_TODAY: 'GET YOUR APP TODAY',

        //all ads

        BUY_AND_SELL_ANYTHING: 'Buy & sell anything online in Sri Lanka',
        SEACRH_BY_LOCATION: 'Search by Location',
        LOCATION: 'Location',
        APPLY_LOCATION: 'Apply Location',
        SEARCH_BY_CATEGORY: 'Search by Category',
        LOAD_MORE: 'Load More',

        //Search Page(Motors,Property etc)

        FILTERS: 'Filters',
        PRICE_MIN: 'Price Min',
        PRICE_MAX: 'Price Max',
        MAIN_CATEGORY: 'Main Category',
        CATEGORY: 'Category',
        SUB_CATEGORY: 'Sub-Categories',
        ALL: 'All',
        HEAVY_VEHICLES: 'Heavy vehicles',
        VANS: 'Vans',
        THREE_WHEELERS: 'Three wheelers',
        AUTO_PARTS_AND_ACCESSORIES: 'Auto parts & accessories',
        FOR_RENT_OR_HIRE: 'For rent or hire',
        MOTORBIKES: 'Motorbikes',
        CARS: 'Cars',
        AUTO_SERVICES: 'Auto services',
        BUSES_AND_LORRIES: 'Buses & lorries',
        BICYCLE: 'Bicycle',
        APPLY_FILTER: 'Apply Filter',
        SORT_BY: 'Sort By',
        YOU_HAVE_SEEN_ALL: 'You Have Seen All',
        PRICE_LOW: 'Price: Low',
        PRICE_HIGH: 'Price: High',
        NEWEST_TO_TOP: 'Date: Newest on top',
        MODEL_YEAR: 'Model Year',
        MILEAGE_MIN: 'Mileage Min',
        MILEAGE_MAX: 'Mileage Max',
        CONDITION: 'Condition',
        BRAND_NEW: 'Brand New',
        REGISTERED: 'Registered',
        UNREGISTERED: 'Unregistered',
        TRANSMISSION: 'Transmission',
        ENGINE_CAPACITY: 'Engine Capacity',
        ANY: 'Any',
        AUTOMATIC: 'Automatic',
        MANUAL: 'Manual',
        TIPTONIC: 'Tiptronic',
        ELECTRONIC_HOME_APPLIANCES: 'Electronic home appliances',
        AUDIO_AND_SPEAKERS: 'Audio & speakers',
        LAPTOP_DESKTOP_AND_TABLETS: 'Laptop desktop & tablets',
        COMPUTER_ACCESSORIES: 'Computer accessories',
        MOBILE_ACCESSORIES: 'Mobile accessories',
        MOBILE_PHONES: 'Mobile phones',
        CAMERAS_AND_CAMCORDERS: 'Cameras & camcorders',
        ELECTRONIC_SERVICES_AND_REPAIRS: 'Electronic services & repairs',
        OTHER_ELECTRONICS: 'Other electronics',
        // CONDITION: '{lan.CONDITION}',
        NEW: 'new',
        USED: 'Used',
        BRAND_NAME: 'Brand Name',
        MODEL: 'Model',
        COMMERCIAL_PROPERTY_FOR_SALE: 'Commercial property for sale',
        HOUSES_AND_VILLAS_FOR_SALE: 'Houses & villas for sale',
        APARTMENTS_FOR_SALE: 'Apartments for sale',
        LANDS_FOR_SALE: 'Lands for sale',
        BED_ROOMS: 'Bed Rooms',
        BATH_ROOMS: 'Bath Rooms',
        FLOOR_AREA_MIN: 'Floor Area Min',
        FLOOR_AREA_MAX: 'Floor Area Max',
        PARKING_SPACE: 'Parking Space',
        FURNISHED: 'Furnished',
        LAND_SIZE_MIN: 'Land Size Min',
        LAND_SIZE_MAX: 'Land Size Max',
        PRIVATE_ENTRANCE: 'Private Entrance',
        PRIVATE_BATHROOM: 'Private Bathroom',

        //filter

        APARTMENTS_FOR_RENT: 'Apartments for rent',
        HOUSES_FOR_RENT: 'Houses for rent',
        ROOMS_FOR_RENT: 'Rooms for rent',
        COMMERCIAL_PROPERTY_FOR_RENT: 'Commercial property for rent',
        'SHORT_TERM_(DAILY)': 'Short term (daily)',
        'SHORT_TERM_(MONTHLY)': 'Short term (monthly)',
        LANDS_FOR_RENT: 'Lands for rent',
        HOME_AND_GARDEN: 'Home & garden',
        KIDS_AND_BABY_ITEMS: 'Kids & baby items',
        FASHION_AND_BEAUTY: 'Fashion & beauty',
        HOBBY_AND_SPORTS: 'Hobby & sports',
        PETS_AND_ANIMALS: 'Pets & animals',
        BUSINESS_AND_INDUSTRY: 'Business & industry',
        WANTED_AND_LOOKING_FOR: 'Wanted & looking for',
        LOST_AND_FOUND: 'Lost & found',
        SERVICES: 'Services',
        AGRICULTURE: 'Agriculture',
        ESSENTIALS: 'Essentials',
        TRAVEL_AND_LEISURE: 'Travel & leisure',

        //footer
        FOOTER_WELCOME: 'Welcome to the new marketplace designed for modern Sri Lankans. Find the best deals on Vehicles, Property, Electronics and much more. Choose from a wide range of high ',
        FOOTER_DOWNLOAD_APP: 'Download the app to upgrade your life!',
        FAQ: 'FAQ',
        BLOG: 'Blog',
        MEMBERSHIP: 'Membership',
        PRIVACY_POLICY: 'Privacy Policy',
        TERMS_AND_CONDITIONS: 'Terms & Conditions',
        CONTACT: 'CONTACT',
        HOTLINE: 'Hotline',
        EMAIL: 'Email',
        CONNECT_WITH_US: 'Connect with us:',
        COPYRIGHTS: 'Copyright © 2021 Saruwata.lk - All Rights Reserved',

        //Post Ad
        POST_AD: 'Post Ad',
        SELECT_APPROPRIATE_CATEGORY: 'Select the appropriate category to post your ad',
        CONTINUE: 'Continue',
        CHECK_YOUR_PHOTOS_AND_CONFIRM: 'Check your photos and confirm',
        ADD_UPTO_10_PHOTOS: 'Add upto 10 photos - You must upload at least one photo*',
        CLICK_UPLOADED_IMAGE_TO_CHANGE_TITTLE: 'click on the uploaded image to change the title image',
        IMAGES_FORMAT: 'Images must be JPG or PNG format (max 15 MB).',
        NO_WATERMARS: 'Do not upload images with watermarks.',
        CONFIRM_IMAGE_UPLOAD: 'Confirm image upload',
        DETAILS_FILLED: 'Details Filled',
        ADD_POST_DISCARD: 'Your Ad post will Discard',
        CANCEL: 'cancel',
        YES: 'Yes',
        IMAGE_CANNOT_CHANGE_LATER: 'The images you selected cannot be changed later..!',
        CLOSE: 'Close',
        OK: 'Ok',
        FILL_IN_THE_DETAILS: 'Fill in the details',
        IMAGES_CANNOT_CHANGE_LATER: 'The images you selected cannot be changed later..!',
        CONTACT_DETAILES: 'Contact details',
        ACCEPT_TERMS: 'By clicking on Post Ad, you accept the Terms of Use, confirm that you will abide by the Safety Tips,and declare that this posting does not include any Prohibited Items.',
        CLEAR_ALL: 'Clear All',
        OKAY: 'Okay',
        WAIT: 'Wait',
        SELECT_RIGHT_SUB_CATEGORY: 'Select your right subcategory',
        TITTLE_IMAGE: 'Title Image',
        NEXT: 'Next',
        MAX_SIZE_15MB: 'Maximum image size is 15MB',
        IMAGE_COUNT_EXCEED: 'Image count exceeded',
        MAX_IMAGE_COUNT_EXCEED_IN_FIRST_10: 'Maximum image count exeeded using first 10 images',
        CHANGE_COVER_IMAGE_FIRST: 'Change cover image first',
        CONTINUE_IMAGE_PROCESSING: 'Continue Image Procesing',
        ASK_FOR_PRICE: 'Ask For Price',
        MAX_72_CHARACTERS: 'Max 72 characters',
        MAX_4000_CHARACTERS: 'Max 4000 characters',
        PER_TOTAL: '/ Total',
        PER_PERCH: '/ Per Perch',
        PER_ACRES: '/ Per Acres',
        PERCHES: ' Perches',
        PER_MONTHLY: ' / Per Month',
        MOBILE_NUMBER: 'Mobile number',
        WHATSAPP_NUMBER: 'Whatsapp number',
        WHATSAPP: 'Whatsapp',
        USE_SAME_NUMBER_FOR_WHATSAPP: 'Use same number for WhatsApp',
        PROFILE_IMAGE: 'Profile Image',
        HELLO: 'Hello, ',
        WHAT_ARE_YOU_LISTING_TODAY: 'What are you listing today?',
        LISTING_SUMMARY: 'Listing Summary',
        PHOTOS_ADDED: 'photos added',
        TITTLE: 'Title',
        DESCRIPTION: 'Description',
        PRICE: 'Price',

        DISTRICT: 'District',
        CITY: 'City',
        MILEAGE: 'Mileage',
        RS: 'Rs',
        FLOOR_AREA_SQFT: 'Floor Area (sqft)',
        LAND_SIZE_ACRES: 'Land Size (Acres)',
        LAND_SIZE_PERCHES: 'Land Size (Perches)',
        LAND_SIZE: 'Land Size',
        TOTAL: 'Total',
        PER_PERC: 'Per Perch',
        PER_ACRE: 'Per Acres',
        UNIT: 'Unit',
        PROPERTY: 'Property',
        BRAND: 'Brand',
        MEMORY_CAPACITY: 'Memory Capacity',
        MEMORY: 'Memory',
        FLOOR_AREA: 'Floor Area',
        BACK: 'Back',
        ALL_STEP_COMPLETED: 'All steps completed - Continue to Promotions',
        BACK_TO_SELECTED_CATEGORY: 'Back to Selected Category',
        HOME: 'Home',
        EDIT_PROFILE: 'Edit Profile',
        MY_ADS: 'My Ads',
        MY_MEMBERSHIP: 'My Membership',
        SHOP_PAGE_SETTING: 'Shop Page Setting',
        FAVORITES: 'Favorites',
        EMAIL_ADDRESS: 'Email Address',
        PASSWORD: 'Password',
        LOG_IN: 'Log In',
        HOW_TO_SELL: 'How to Sell Fast',
        AD_PROMOTION: 'Ad Promotions',
        CONTACT_US: 'Contact Us',
        LOG_OUT: 'Log Out',
        LIKES: 'Likes',
        VIEWS: 'Views',
        POSTED_BY: 'Posted By',
        SEE_ALL_ADS: 'See all ADs from this dealer',
        CLICK_TO_SHOW_PHONE: 'Click to show phone number',
        CHOOSE_LOCATION:'Choose Location',
        ADD_MORE_IMAGES:'Add More Images',


        //membership
        INCREASE_YOUR_SALES: 'Increase your sales with a Saruwata.lk Membership!',
        MEMEBRSHIP_PROVIDE: 'Memberships provide your company a stronger profile on Saruwata.lk, allowing you to reach out to more people. Our membership packages are tailored to provide you with the resources you need to grow your business and enhance your sales.',
        AS_A_MEMBER: "As a member, you'll also receive our newsletter, which contains valuable advice on how to grow your business on Saruwata.lk.",
        BECOME_A_MEMBER: 'Become a Member',
        BENEFITS_OF_MEMBERSHIP: 'Benefits of Membership',
        STEP_UP_YOUR_SALES: 'Step up your sales with Membership services and make money easier!',
        POST_MORE_ADS: 'Post More Ads',
        POST_MORE_ADS_DESC: "With a Membership, you may post more adverts! Rather than worrying about ad constraints, concentrate on designing good advertising that efficiently sells your products. You'll sell more if you publish more advertising!",
        FREE_PROMOTION_VOUCHERS: 'Free Promotions Vouchers',
        FREE_PROMOTION_VOUCHERS_DESC: "Free advertising promotion! Every month, you'll receive free vouchers that you can use to add Super Ad, Jump Ad, Quick Sell, or Featured Ad promotions to your ads and get even more responses.",
        RECOGNIZE_AS_A_MEMBER: "Recognize as a Member",
        RECOGNIZE_AS_A_MEMBER_DESC: 'The member badge appears on all member ads, distinguishing your ads from those posted by individual sellers! We also provide special badges to make your ads more unique.',
        MEMBER_SHOP: 'Member Shop',
        MEMBER_SHOP_DESC: 'Create your own brand on saruwata by dedicating an entire page to your company! This is similar to having your own online store in that all of your ads are listed in one location, and you can customize it by adding your tagline, logo, cover picture, and other business information.',
        TRACK_YOUR_AD: 'Track your Ad',
        TRACK_YOUR_AD_DESC: 'On a daily basis, see how much interest your ad has generated among buyers! To understand the performance of an ad, you can track the number of views.',
        DEDICATE_ACCOUNT_MANAGER: 'Dedicate Account Manager',
        DEDICATE_ACCOUNT_MANAGER_DESC: 'Get a personal executive to assist you with all of your needs, including ad posting! Throughout your membership, you will also have access to our sales and customer support teams.',
        ADVERTISE_YOUR_PRODUCTS: 'Advertise your products',
        AND_SERVICES_ON: 'and services on',
        SARUWATA_LK_NOW: 'Saruwata.lk now!',
        WE_WILL_HELP_YOU: 'We will help create your shop and list your products on Saruwata.lk',
        REQUEST_A_CALL_BACK: 'Request a call back now!',
        LET_US_HELP: 'Let us help you!',
        YOUR_NAME: 'Your Name',
        PHONE_NUMBER: 'Phone number',
        YOUR_EMAIL: 'Your Email',
        SELECT_CATEGORY: 'Select Category',
        BUSINESS_LOCATION: 'Business Location',
        SUBMIT: 'SUBMIT',

    };


    sinhala = {
        DOWNLOAD_OUR_MOBILE_APP: 'අපගේ app එක බාගත කරගන්න',

        // Main Nav
        POST_YOUR_AD: 'ඔබගේ දැන්වීම පළ කරන්න',
        ALL_ADS: 'සියලුම දැන්වීම්',
        MOTORS: 'වාහන',
        // MOBILE_AND_ELECTRONICS: 'ජංගම සහ ඉලෙක්ට්‍රොනික',
        MOBILE_AND_ELECTRONICS: 'ඉලෙක්ට්‍රොනික උපකරණ',
        MOBILES_AND_ELECTRONICS: 'ඉලෙක්ට්‍රොනික උපකරණ',
        PROPERTY_FOR_SALE: 'දේපල විකිණීමට',
        PROPERTY_FOR_RENT: 'දේපල කුලියට දීමට',
        CLASSIFIEDS: 'ලුහුඬු දැන්වීම්',
        OTHERS: 'වෙනත්',
        OTHER: 'වෙනත්',

        //Landing Page
        WELCOME_TO_SARUWATA: 'saruwata.lk වෙත සාදරයෙන් පිළිගන්නෙමු.',
        THE_NEW_MARKETPLACE_FOR_MODERN_SRILANKANS: 'නූතන ශ්‍රී ලාංකිකයන් සඳහා වූ නවතම වෙළෙඳපොළ',
        SEARCH: 'ඔබ සොයන්නේ මොනවාද?',
        FIND_THE_BEST: 'වාහන, දේපළ, ඉලෙක්ට්‍රොනික උපකරණ සහ තවත් දේ පිළිබඳ හොඳම ගනුදෙනු සොයන්න. විශ්වාසදායක විකුණුම්කරුවන්ගෙන් උසස් තත්ත්වයේ දැන්වීම් පුළුල් පරාසයකින් තෝරාගන්න.',
        VIEW_ALL_ADS: 'සියලුම දැන්වීම් පෙන්වන්න',
        CATEGORIES: 'කාණ්ඩ',
        BROWSE_THROUGH: 'අපගේ වඩාත් ජනප්‍රිය කාණ්ඩ කිහිපයක් හරහා බ්‍රවුස් කරන්න',
        // POST_YOUR_AD: 'ඔබගේ දැන්වීම පළ කරන්න',
        JUST_IN_FEW: 'සුළු ක්ලික්  කිරීම් කිහිපයකින්ම ඔබේ දැන්වීම් පළ කරගන්න',
        ADD_UPTO_10: 'සෑම දැන්වීමකටම ඡායාරූප 10ක් දක්වා එක් කළ හැක',
        CHOOSE_FROM_A_WIDE: 'පුළුල් පරාසයක කාණ්ඩ වලින් තෝරන්න',
        FILL_IN_AVAILABLE: 'පවතින විස්තර පමණක් පුරවන්න',
        PUBLISH_ADS_AUTOMATICALLY: 'ප්‍රමාදයකින් තොරව ස්වයංක්‍රීයවම ඔබේ දැන්වීම් පළ කරගන්න',
        USE_DIFFERENT_CONTACT: 'දැන්වීම් සදහා විවිධ දුරකථන අංක භාවිතා කරන්න',
        BUY_AND_SELL_ON: 'සරුවට app මගින් මිලදී ගැනීම් සහ විකිණීම් සිදු කරන්න',
        ITS_TIME_TO: 'මේ ඔබගේ ජීවිතය අලුත් කිරීමට කාලයයි!',
        TAKE_CAREFUL_LOOK: 'ඔබ භාවිත කළ මෝටර් රථයක් , භාවිත කළ දුරකථනයක් , ඔබ කිසිදා වාදනය නොකරන ගිටාරයක්, විසිතුරු පුටුවක් හෝ ස්වර්ණාභරණ, පොත්පත් සහ විලාසිතා භාණ්ඩ. හොදින් ඔබ අවට බලන්න! - saruwata.lk හි ඔබේ දැන්වීම් පළ කිරීමට ඔබත් සූදානම්ද?',
        GET_YOUR_APP_TODAY: 'අදම ඔබගේ app එක  ලබා ගන්න',


        //all ads

        BUY_AND_SELL_ANYTHING: 'ශ්‍රී ලංකාවේ ඕනෑම දෙයක් අන්තර්ජාලය හරහා මිලදී ගන්න සහ විකුණන්න',
        SEACRH_BY_LOCATION: 'ප්‍රදේශය අනුව සොයන්න',
        LOCATION: 'ප්‍රදේශය',
        APPLY_LOCATION: 'ප්‍රදේශය තෝරන්න',
        SEARCH_BY_CATEGORY: 'කාණ්ඩය අනුව සොයන්න',
        LOAD_MORE: 'තවදුරටත් බලන්න',


        //Search Page(Motors,Property etc)

        FILTERS: 'ප්‍රේරණය කරන්න',
        PRICE_MIN: 'අවම මිල',
        PRICE_MAX: 'උපරිම මිල',
        MAIN_CATEGORY: 'කාණ්ඩය',
        CATEGORY: 'කාණ්ඩය',
        SUB_CATEGORY: 'උපකාණ්ඩය',
        ALL: 'සියළුම',
        HEAVY_VEHICLES: 'බර වාහන',
        VANS: 'වෑන් රථ',
        THREE_WHEELERS: 'ත්‍රිරෝද රථ',
        AUTO_PARTS_AND_ACCESSORIES: 'වාහන අමතර කොටස් සහ උපාංග',
        FOR_RENT_OR_HIRE: 'කුලී පදනමට',
        MOTORBIKES: 'යතුරුපැදි',
        CARS: 'මෝටර් රථ',
        AUTO_SERVICES: 'රථවාහන සේවා',
        BUSES_AND_LORRIES: 'බස් රථ හා ලොරි රථ',
        APPLY_FILTER: 'ප්‍රේරණය කරන්න',
        SORT_BY: 'පෙළගස්වන්න',
        YOU_HAVE_SEEN_ALL: 'සියල්ල අවසානයි',
        PRICE_LOW: 'මිල අඩුවෙන ලෙස',
        PRICE_HIGH: 'මිල වැඩිවෙන ලෙස',
        NEWEST_TO_TOP: 'නවතම',
        MODEL_YEAR: 'නිෂ්පාදිත වර්ෂය',
        MILEAGE_MIN: 'අවම සැතපුම් ගණන',
        MILEAGE_MAX: 'උපරිම සැතපුම් ගණන',
        CONDITION: 'අන්දම',
        BRAND_NEW: 'නවතම',
        REGISTERED: 'ලියාපදිංචි කළ',
        UNREGISTERED: 'ලියාපදිංචි නොකළ',
        TRANSMISSION: 'ගියර් පද්ධති',
        ENGINE_CAPACITY: 'එන්ජිමේ ධාරිතාව',
        ANY: 'ඕනෑම',
        AUTOMATIC: 'ස්වයංක්‍රීය',
        MANUAL: 'කායික ගියර්',
        TIPTONIC: 'ටිප්ට්‍රොනික්',
        ELECTRONIC_HOME_APPLIANCES: 'ඉලෙක්ට්‍රොනික ගෘහ උපකරණ',
        AUDIO_AND_SPEAKERS: 'ශ්‍රව්‍ය උපකරණ හා ස්පීකර්',
        LAPTOP_DESKTOP_AND_TABLETS: 'ලැප්ටොප්, ඩෙස්ක්ටොප් සහ ටැබ්ලට්',
        COMPUTER_ACCESSORIES: 'පරිගණක උපාංග',
        MOBILE_ACCESSORIES: 'ජංගම දුරකථන  උපාංග',
        MOBILE_PHONES: 'ජංගම දුරකථන',
        CAMERAS_AND_CAMCORDERS: 'ඡායා රූප සහ වීඩියෝ කැමරා',
        ELECTRONIC_SERVICES_AND_REPAIRS: 'ඉලෙක්ට්‍රොනික සේවා සහ අලුත්වැඩියා',
        OTHER_ELECTRONICS: 'අනෙකුත් ඉලෙක්ට්‍රොනික උපකරණ',
        NEW: 'නවතම',
        USED: 'භාවිතා කළ',
        BRAND_NAME: 'වෙළඳ නාමය',
        MODEL: 'මාදිලිය',
        COMMERCIAL_PROPERTY_FOR_SALE: 'වාණිජ දේපල විකිණීමට',
        HOUSES_AND_VILLAS_FOR_SALE: 'නිවාස සහ විලාස් විකිණීමට',
        APARTMENTS_FOR_SALE: 'මහල් නිවාස විකිණීමට',
        LANDS_FOR_SALE: 'ඉඩම් විකිණීමට',
        BED_ROOMS: 'කාමර',
        BATH_ROOMS: 'නාන කාමර',
        FLOOR_AREA_MIN: 'අවම භූමි ප්‍රදේශය',
        FLOOR_AREA_MAX: 'උපරිම  භූමි ප්‍රදේශය',
        PARKING_SPACE: 'වාහන නැවැත්වීමේ ඉඩකඩ',
        FURNISHED: 'ලී බඩු සමග',
        LAND_SIZE_MIN: 'අවම ඉඩම් ප්‍රමාණය',
        LAND_SIZE_MAX: 'උපරිම ඉඩම් ප්‍රමාණය',
        PRIVATE_ENTRANCE: 'පෞද්ගලික පිවිසුම',
        PRIVATE_BATHROOM: 'පෞද්ගලික නානකාමරය',
        ADD_MORE_IMAGES:'Add More Images',

        APARTMENTS_FOR_RENT: 'මහල් නිවාස කුලීයට දීම',
        HOUSES_FOR_RENT: 'නිවාස කුලීයට දීම',
        ROOMS_FOR_RENT: 'කාමර සහ ඇමුණුම් කුලීයට',
        COMMERCIAL_PROPERTY_FOR_RENT: 'වාණිජ දේපල කුලීයට',
        'SHORT_TERM_(DAILY)': 'කෙටි කාලීන (දිනපතා)',
        'SHORT_TERM_(MONTHLY)': 'කෙටි කාලීන (මාසිකව)',
        LANDS_FOR_RENT: 'ඉඩම් කුලීයට දීම',
        HOME_AND_GARDEN: 'නිවාස සහ ගේවතු',
        KIDS_AND_BABY_ITEMS: 'ළමයින් සහ ළදරු අයිතම',
        FASHION_AND_BEAUTY: 'විලාසිතා සහ රූපලාවණ්‍ය නිෂ්පාදන',
        HOBBY_AND_SPORTS: 'විනෝදාංශ සහ ක්‍රීඩා',
        PETS_AND_ANIMALS: 'සුරතල් සතුන් සහ සතුන්',
        BUSINESS_AND_INDUSTRY: 'ව්‍යාපාර හා කර්මාන්ත',
        WANTED_AND_LOOKING_FOR: 'අවශ්‍ය සහ සොයමින්',
        LOST_AND_FOUND: 'නැතිවූ සහ සොයාගත්',
        SERVICES: 'සේවාවන්',
        AGRICULTURE: 'කෘෂිකර්ම',
        ESSENTIALS: 'අත්‍යාවශ්‍යය භාණ්ඩ',
        TRAVEL_AND_LEISURE: 'සංචාරක සේවා',

        //footer
        FOOTER_WELCOME: 'නූතන  ශ්‍රී ලාංකිකයන් සඳහා නිර්මාණය වූ නවතම වෙළඳපොළට සාදරයෙන් පිළිගනිමු. වාහන, දේපළ, ඉලෙක්ට්‍රොනික උපකරණ සහ තවත් ',
        FOOTER_DOWNLOAD_APP: 'ඔබගේ ජීවිතය වැඩිදියුණු කිරීමට අපගේ app එක බාගත කරගන්න ',
        FAQ: 'නිතර අසන ප්‍රශ්න',
        BLOG: 'බ්ලොග්',
        MEMBERSHIP: 'සාමාජිකත්වය',
        PRIVACY_POLICY: 'පුද්ගලික ප්‍රතිපදාව',
        TERMS_AND_CONDITIONS: 'කොන්දේසි',
        CONTACT: 'අමතන්න',
        HOTLINE: 'ක්ෂණික ඇමතුම්',
        EMAIL: 'විද්‍යුත් තැපෑල',
        CONNECT_WITH_US: 'අප හා සම්බන්ධ වන්න:',
        COPYRIGHTS: 'ප්‍රකාශන හිමිකම © 2021 Saruwata.lk - සියලුම හිමිකම් ඇවිරිණි.',

        //Post Ad
        POST_AD: 'දැන්වීම පළ කරන්න',
        SELECT_APPROPRIATE_CATEGORY: 'ඔබේ දැන්වීම පළ කිරීමට සුදුසු ම කාණ්ඩය තෝරන්න',
        CONTINUE: 'ඉදිරියට යන්න',
        CHECK_YOUR_PHOTOS_AND_CONFIRM: 'ඔබගේ ඡායාරූප පරීක්ෂා කර තහවුරු කරන්න',
        ADD_UPTO_10_PHOTOS: 'ඡායාරූප 10ක් දක්වා එක් කළහැක - ඔබ අවම වශයෙන් එක් ඡායාරූපයක් එක්  කළ යුතුය*',
        CLICK_UPLOADED_IMAGE_TO_CHANGE_TITTLE: 'මාතෘකා රූපය වෙනස් කිරීමට එක්  කළ රූපය මත ක්ලික් කරන්න',
        IMAGES_FORMAT: 'පින්තූර JPG හෝ PNG ආකෘතිය (උපරිම 15 MB) විය යුතුය.',
        NO_WATERMARS: 'දියසළකුණ සලකුණු සහිත පින්තූර එක් නොකරන්න.',
        CONFIRM_IMAGE_UPLOAD: 'රූපය එක් කිරීම තහවුරු කරන්න',
        DETAILS_FILLED: 'විස්තර පුරවන්න ',
        ADD_POST_DISCARD: 'ඔබේ දැන්වීම් පළ කිරීම ඉවත දමනු ඇත',
        CANCEL: 'අවලංගු කරන්න',
        YES: 'ඔව්',
        IMAGE_CANNOT_CHANGE_LATER: 'ඔබ තෝරාගත් ඡායාරූප පසුව වෙනස් කළ නොහැක..!',
        CLOSE: 'ඉවත් කරන්න',
        OK: 'හරි',
        FILL_IN_THE_DETAILS: 'විස්තර පුරවන්න',
        CONTACT_DETAILES: 'ඇමතුම් විස්තර',
        ACCEPT_TERMS: 'දැන්වීම පළකරන්න  මත ක්ලික් කිරීමෙන්, ඔබ භාවිත නියම පිළිගන්නා බවත්, ආරක්‍ෂිත උපදෙස් පිළිපදින බවත්, මෙම පළකිරීමේ කිසිදු තහනම් අයිතමයක් ඇතුළත් නොවන බව තහවුරු කරමි.',
        CLEAR_ALL: 'සියල්ල අවලංගු කරන්න',
        OKAY: 'හරි',
        WAIT: 'රැදීසිටින්න',
        SELECT_RIGHT_SUB_CATEGORY: 'ඔබේ නිවැරදි උපකාණ්ඩය  තෝරන්න',
        TITTLE_IMAGE: 'ප්‍රධාන රූපය',
        NEXT: 'ඊලග ',
        MAX_SIZE_15MB: 'ඡායා රූපයේ උපරිම ප්‍රමාණය 15MB වේ',
        IMAGE_COUNT_EXCEED: 'ඡායා රූප ගණන ඉක්මවා ඇත',
        MAX_IMAGE_COUNT_EXCEED_IN_FIRST_10: 'පළමු රූප 10 න් පසුව උපරිම ඡායාරූප පසුකර ඇත.',
        CHANGE_COVER_IMAGE_FIRST: 'මුලින්ම කවරයේ රූපය වෙනස් කරන්න',
        CONTINUE_IMAGE_PROCESSING: 'ඡායා රූප සැකසීම දිගටම කරගෙන යන්න',
        ASK_FOR_PRICE: 'මිල අසා දැනගන්න',
        MAX_72_CHARACTERS: 'උපරිම අකුරු 72ක්  යොදන්න',
        MAX_4000_CHARACTERS: 'උපරිම අකුරු 4000ක්  යොදන්න',
        PER_TOTAL: '/සම්පුර්ණයෙන්ම ',
        PER_PERCH: '/පර්චසයක්',
        PER_ACRES: '/අක්කරයකට',
        PERCHES: 'පර්චස්',
        PER_MONTHLY: '/මාසයකට ',
        MOBILE_NUMBER: 'දුරකථන අංකය',
        WHATSAPP_NUMBER: 'වට්ස්ඇප් අංකය',
        WHATSAPP: 'වට්ස්ඇප්',
        USE_SAME_NUMBER_FOR_WHATSAPP: 'වට්ස්ඇප් සඳහාද ඉහත අංකය භාවිතා කරන්න ',
        PROFILE_IMAGE: 'ප්‍රොෆයිල් රූපය',
        HELLO: 'හෙලෝ,',
        WHAT_ARE_YOU_LISTING_TODAY: 'ඔබ අද ලැයිස්තුගත කරන්නේ මොනවද ? ',
        LISTING_SUMMARY: 'සාරාංශය',
        PHOTOS_ADDED: 'ඡායාරූප එකතු කරන ලදී',
        PHOTOS_ADDED_1: 'ඡායාරූප ',
        PHOTOS_ADDED_2: 'ක් එකතු කරන ලදී',
        TITTLE: 'දැන්වීමේ මාතෘකාව ',
        DESCRIPTION: 'විස්තරය ',
        PRICE: 'මිල',

        DISTRICT: 'දිස්ත්‍රික්කය ',
        CITY: 'නගරය',
        MILEAGE: 'සැතපුම් ගණන',
        RS: 'රු',
        FLOOR_AREA_SQFT: 'බිම් ප්‍රදේශය (වර්ග අඩි)',
        LAND_SIZE_ACRES: 'ඉඩමේ ප්‍රමාණය (අක්කර)',
        LAND_SIZE_PERCHES: 'ඉඩමේ ප්‍රමාණය (පර්චස්)',
        LAND_SIZE: 'ඉඩමේ ප්‍රමාණය',
        TOTAL: 'සම්පුර්ණයෙන්ම',
        PER_PERC: 'පර්චසයකට',
        PER_ACRE: 'අක්කරයකට',
        UNIT: 'ඒකකය',
        PROPERTY: 'දේපල',
        BRAND: 'වෙළඳ නාමය',
        MEMORY_CAPACITY: 'මෙමරි ධාරිතාව',
        MEMORY: 'මෙමරි ධාරිතාව',
        FLOOR_AREA: 'බිම් ප්‍රදේශය ',
        BACK: 'Back',
        ALL_STEP_COMPLETED: 'සියලුම පියවර සම්පූර්ණ කර ඇත - උසස්වීම් වෙත ඉදිරියට යන්න',
        BACK_TO_SELECTED_CATEGORY: 'තෝරාගත් කාණ්ඩය වෙත ආපසු',
        HOME: 'Home',
        EDIT_PROFILE: 'ගිණුම සංස්කරණය කරන්න',
        MY_ADS: 'මගේ දැන්වීම්',
        MY_MEMBERSHIP: 'මගේ සාමාජිකත්වය',
        SHOP_PAGE_SETTING: 'Shop පිටුවෙහි සැකසුම්',
        FAVORITES: ' ප්‍රියතම Ads',
        EMAIL_ADDRESS: 'විද්‍යුත් තැපැල් ලිපිනය',
        PASSWORD: 'මුරපදය',
        LOG_IN: 'ගිණුමට ඇතුල් වන්න',
        HOW_TO_SELL: 'ඉක්මනින් විකුණන්නේ කෙසේද',
        AD_PROMOTION: 'දැන්වීම් ප්‍රවර්ධන',
        CONTACT_US: 'අපව අමතන්න',
        LOG_OUT: 'ගිණුමෙන් ඉවත් වෙන්න',
        LIKES: 'මනාප',
        VIEWS: 'දැකීම්',
        POSTED_BY: 'පළ කළේ',
        SEE_ALL_ADS: 'මොහුගේ සියලුම දැන්වීම් බලන්න',
        CLICK_TO_SHOW_PHONE: 'දුරකථන අංකය බැලීමට ක්ලික් කරන්න',
        CHOOSE_LOCATION:'ස්ථානය තෝරන්න',

        //membership
        INCREASE_YOUR_SALES: 'Saruwata.lk සාමාජිකත්වය සමඟ ඔබේ විකුණුම් වැඩි කර ගන්න!',
        MEMEBRSHIP_PROVIDE: 'සාමාජිකත්වය මඟින් ඔබේ සමාගමට Saruwata.lk හි ශක්තිමත් පැතිකඩක් සපයන අතර, ඔබට වැඩි පිරිසක් වෙත ළඟා වීමට ඉඩ සලසයි. අපගේ සාමාජික පැකේජ සකස් කර ඇත්තේ ඔබේ ව්‍යාපාරය වර්ධනය කිරීමට සහ ඔබේ විකුණුම් වැඩි දියුණු කිරීමට අවශ්‍ය සම්පත් ඔබට ලබා දීමටය.',
        AS_A_MEMBER: "සාමාජිකයෙකු ලෙස, Saruwata.lk හි ඔබේ ව්‍යාපාරය වර්ධනය කර ගන්නේ කෙසේද යන්න පිළිබඳ වටිනා උපදෙස් අඩංගු අපගේ පුවත් පත්‍රිකාව ද ඔබට ලැබෙනු ඇත.",
        BECOME_A_MEMBER: 'සාමාජිකයෙකු වන්න',
        BENEFITS_OF_MEMBERSHIP: 'සාමාජිකත්වයේ ප්‍රතිලාභ',
        STEP_UP_YOUR_SALES: 'සාමාජිකත්ව සේවා සමඟින් ඔබේ විකුණුම් වැඩි කර පහසුවෙන්ම මුදල් උපයා ගන්න!',
        POST_MORE_ADS: 'තවත් දැන්වීම් පළ කරන්න',
        POST_MORE_ADS_DESC: "සාමාජිකත්වය ලබා ගැනීම සමඟ, ඔබට දැන්වීම් රැසක් පළ කර ගත හැකිය! දැන්වීම් පළ කිරීමේදී සිදුවන අවහිරතා ගැන කරදර වෙනවාට වඩා, ඔබේ නිෂ්පාදන කාර්යක්ෂමව අලෙවි කරන හොඳ වෙළඳ දැන්වීම් සැලසුම් කිරීම කෙරෙහි අවධානය යොමු කරන්න. ඔබ වැඩියෙන් ප්‍රචාරණයක් කළහොත් ඔබට වැඩිපුර අලෙවි කර ගත හැක!",
        FREE_PROMOTION_VOUCHERS: 'නොමිලේ ප්‍රවර්ධන වවුචර',
        FREE_PROMOTION_VOUCHERS_DESC: "නොමිලේ ප්‍රචාරණ ප්‍රවර්ධනය! සෑම මසකම, ඔබට ඔබේ දැන්වීම්වලට Super Ad, Jump Ad, Quick Sell, හෝ Feature Ad ප්‍රවර්ධන එක් කිරීමට සහ ඊටත් වඩා ප්‍රතිචාර ලබා ගැනීමට භාවිත කළ හැකි නොමිලේ වවුචර ඔබට ලැබෙනු ඇත.",
        RECOGNIZE_AS_A_MEMBER: "සාමාජිකයෙකු ලෙස හඳුනා දීම",
        RECOGNIZE_AS_A_MEMBER_DESC: 'සාමාජික ලාංඡනය සියලුම සාමාජික දැන්වීම්වල දිස්වන අතර, ඔබේ දැන්වීම් තනි විකුණුම්කරුවන් විසින් පළ කරන ලද දැන්වීම්වලින් වෙන්කර හඳුනා ගනීමට එය උපකාරී වේ! තවද අපි ඔබේ දැන්වීම් වඩාත් අද්විතීය කිරීමට විශේෂ ලාංඡන ද ලබා දෙන්නෙමු.',
        MEMBER_SHOP: 'සාමාජිකත්ව Shop පිටුව',
        MEMBER_SHOP_DESC: 'ඔබේ සමාගමට සම්පූර්ණ පිටුවක් කැප කිරීමෙන් Saruwata.lk  හි ඔබේම සන්නාමයක් සාදා ගන්න! මෙය ඔබගේම අන්තර්ජාල වෙළඳසැලක් තිබීමට සමාන වන අතර ඔබගේ සියලුම දැන්වීම් එක් ස්ථානයක ලැයිස්තුගත කර ඇති අතර, ඔබගේ ටැග්ලයින්, ලාංඡනය, cover පින්තූරය සහ වෙනත් ව්‍යාපාරික තොරතුරු එක් කිරීමෙන් ඔබට එය ඔබේම විදිහට සකස් කර ගත කළ හැක.',
        TRACK_YOUR_AD: 'ඔබගේ දැන්වීම් නිරීක්ෂණය කරගන්න',
        TRACK_YOUR_AD_DESC: 'දෛනික පදනමින්, ඔබේ දැන්වීම ගැන ගැනුම්කරුවන් අතර කෙතරම් උනන්දුවක් ජනිත වී ඇත්දැයි බලන්න! දැන්වීමක කාර්ය සාධනය තේරුම් ගැනීමට, ඔබට බැලීම් ගණන නිරීක්ෂණය කළ හැකිය.',
        DEDICATE_ACCOUNT_MANAGER: 'කැපවූ  ගිණුම් කළමනාකරුවෙකු',
        DEDICATE_ACCOUNT_MANAGER_DESC: 'දැන්වීම් පළ කිරීම ඇතුළුව, ඔබගේ සියලු අවශ්‍යතා සඳහා ඔබට සහාය වීමට පුද්ගලික විධායකයෙකු ලබා ගන්න! ඔබගේ සාමාජිකත්වය පුරාවටම, ඔබට අපගේ විකුණුම් සහ පාරිභෝගික සහාය කණ්ඩායම් වෙතද ඕනෑම වෙලවක ප්‍රවේශ විය හැක.',
        ADVERTISE_YOUR_PRODUCTS: 'දැන්ම Saruwata.lk හි ',
        AND_SERVICES_ON: 'ඔබගේ නිෂ්පාදන සහ සේවාවන්',
        SARUWATA_LK_NOW: 'ප්‍රචාරණය කරන්න!',
        WE_WILL_HELP_YOU: 'Saruwata.lk හි ඔබේ වෙළඳසැල නිර්මාණය කිරීමට සහ ඔබේ නිෂ්පාදන ලැයිස්තුගත කිරීමට අපි උදවු කරන්නෙමු',
        REQUEST_A_CALL_BACK: 'දැන්ම ම  ප්‍රතිචාර ඇමතුමක් ලබා ගන්න!',
        LET_US_HELP: 'අපි ඔබට උදව් කරමු!',
        YOUR_NAME: 'ඔබගේ නම',
        PHONE_NUMBER: 'දුරකතන අංකය',
        YOUR_EMAIL: 'ඔබේ විද්‍යුත් තැපැල් ලිපිනය',
        SELECT_CATEGORY: 'කාණ්ඩය තෝරන්න',
        BUSINESS_LOCATION: 'ව්‍යාපාරික ස්ථානය',
        SUBMIT: 'යොමු කරන්න',



    };



    tamil = {

        DOWNLOAD_OUR_MOBILE_APP: 'Download Our Mobile App',

        //Main Nav
        ALL_ADS: 'அனைத்து விளம்பரங்கள்',
        MOTORS: 'வாகனங்கள்',
        MOBILE_AND_ELECTRONICS: 'கைபேசி மற்றும் இலத்திரனியல் ',
        MOBILES_AND_ELECTRONICS: 'கைபேசி மற்றும் இலத்திரனியல் ',
        PROPERTY_FOR_SALE: 'வீடுஃகாணி விட்பனைக்கு',
        PROPERTY_FOR_RENT: 'வீடுஃகாணி வாடகைக்கு',
        CLASSIFIEDS: 'வகைப்படுத்தப்பட்ட',
        OTHERS: 'மற்றவை',
        OTHER: 'மற்றவை',
        //Landing Page
        WELCOME_TO_SARUWATA: 'saruwata.lk விற்கு அன்புடன் வரவேற்கிறோம்',
        THE_NEW_MARKETPLACE_FOR_MODERN_SRILANKANS: 'நவீன இலங்கையர்களுக்கான புதிய சந்தை',
        SEARCH: 'தேடுக',
        FIND_THE_BEST: 'வாகனங்கள், வீடுஃகாணி, இலத்திரனியல் கருவிகள் மற்றும் பலவற்றை சிறந்த சலுகையில் கண்டறியவும். நம்பகமான விற்பனையாளர்களிடமிருந்து பலதரப்பட்ட உயர்தர விளம்பரங்களில் இருந்து தேர்வு செய்க..',
        VIEW_ALL_ADS: 'அனைத்து விளம்பரங்களையும் பார்க்க',
        CATEGORIES: 'வகைகள்',
        BROWSE_THROUGH: 'எங்களின் மிகவும் பிரபலமான வகைகளினுடாக தேடுக ',
        POST_YOUR_AD: 'உங்கள் விளம்பரத்தை வெளியிட ',
        JUST_IN_FEW: 'ஒரு சில கிளிக்ஸ்களில்.',
        ADD_UPTO_10: 'ஒவ்வொரு விளம்பரத்திலும் 10 படங்கள் வரை சேர்க்கலாம் ',
        CHOOSE_FROM_A_WIDE: 'பரந்த அளவிலான வகைகளிலிருந்து தெரிவு செய்க ',
        FILL_IN_AVAILABLE: 'fpilf;Fk; tptuq;fis kl;Lk; epug;gTk;',
        PUBLISH_ADS_AUTOMATICALLY: 'தாமதமின்றி விளம்பரங்களை தானாக வெளியிடவும்',
        USE_DIFFERENT_CONTACT: 'விளம்பரங்களில் வெவ்வேறு தொலைபேசி எண்களைப் பயன்படுத்தவும்',
        BUY_AND_SELL_ON: 'சருவட அப் இல் வாங்கவும் விற்கவும் முடியும் ',
        ITS_TIME_TO: 'உங்கள் வாழ்க்கையை புதுப்பிப்பதட்கான நேரம் இது!',
        TAKE_CAREFUL_LOOK: 'கவனமாக பாருங்கள்! நீங்கள் பயன்படுத்திய கார், பயன்படுத்திய தொலைபேசி, நீங்கள் ஒருபோதும் பயன்படுத்தாத கிடார், ஆடம்பரமான நாற்காலி அல்லது நகைகள், புத்தகங்கள் மற்றும் அழகுடைதான பொருட்கள் - உங்கள் விளம்பரங்களை வெளியிட நீங்கள் தயாரா',
        GET_YOUR_APP_TODAY: 'உங்கள் எப் ஐ இன்றே பெற்றுக்கொள்ளுங்கள் ',
        ADD_MORE_IMAGES:'Add More Images',

        //all ads

        BUY_AND_SELL_ANYTHING: 'இலங்கையில் ஆன்லைனில் எதையும் வாங்க மற்றும் விற்க ',
        SEACRH_BY_LOCATION: 'இடத்தின் அடிப்படையில் தேடவும்',
        LOCATION: 'இடம் ',
        APPLY_LOCATION: 'இடத்தை தெரிவு செய்க ',
        SEARCH_BY_CATEGORY: 'பிரிவின் மூலம் தேடுக ',
        LOAD_MORE: 'மேலும் தேடுக ',

        //Search Page(Motors,Property etc)

        FILTERS: 'வடிப்பான்கள்',
        PRICE_MIN: 'குறைந்தபட்சம் விலை ',
        PRICE_MAX: 'அதிகபட்ச விலை ',
        MAIN_CATEGORY: 'அனைத்து வகை',
        CATEGORY: 'வகை',
        SUB_CATEGORY: 'துணை வகைகள்',
        ALL: 'அனைத்தும்',
        HEAVY_VEHICLES: 'கனரக வாகனங்கள்',
        VANS: 'வேன்கள்',
        THREE_WHEELERS: 'முச்சக்கர வண்டிகள்',
        AUTO_PARTS_AND_ACCESSORIES: 'வாகனப்  பாகங்கள் மற்றும் துணைக்கருவிகள் ',
        FOR_RENT_OR_HIRE: 'வாடகைக்கு ',
        MOTORBIKES: 'மோட்டார் சைக்கிள் ',
        CARS: 'கார்கள்',
        AUTO_SERVICES: 'வாகன சார் சேவைகள் ',
        BUSES_AND_LORRIES: 'பேருந்துகள் மற்றும் லாரிகள்',
        APPLY_FILTER: 'வடிகட்டியைப் பயன்படுத்து',
        SORT_BY: 'வரிசைப்படுத்து',
        YOU_HAVE_SEEN_ALL: 'நீங்கள் அனைத்தையும் பார்த்த்துவிட்டீர்கள் ',
        PRICE_LOW: 'குறைந்த விலை ',
        PRICE_HIGH: 'கூடிய விலை',
        NEWEST_TO_TOP: 'Date: புதியது மேலே ',
        MODEL_YEAR: 'மாதிரி ஆண்டு ',
        MILEAGE_MIN: 'குறைந்தபட்ச மைலேஜ்',
        MILEAGE_MAX: 'அதிகபட்சம் மைலேஜ் ',
        CONDITION: 'நிலை',
        BRAND_NEW: 'புத்தம் புதியது',
        REGISTERED: 'Registered',
        UNREGISTERED: 'பதிவு செய்யப்பட்டது',
        TRANSMISSION: 'செலுத்துகை ',
        ENGINE_CAPACITY: 'இயந்திர திறன்',
        ANY: 'ஏதேனும்',
        AUTOMATIC: 'தன்னியக்க',
        MANUAL: 'கையால் செய்யப்படும்',
        TIPTONIC: 'டிப்ட்ரானிக்',
        ELECTRONIC_HOME_APPLIANCES: ' இலத்திரனியல் மற்றும் வீட்டு உபகரணங்கள்',
        AUDIO_AND_SPEAKERS: 'ஒலிக்கருவிகள் மற்றும் ஒளி பெருக்கிகள் ',
        LAPTOP_DESKTOP_AND_TABLETS: 'கணனி, மடிக்கணினி மற்றும் டேப்லெட்கள் ',
        COMPUTER_ACCESSORIES: 'கணினி துணைக்கருவிகள் ',
        MOBILE_ACCESSORIES: 'கையடக்க தொலைபேசி துணைக் கருவிகள்',
        MOBILE_PHONES: 'கையடக்க தொலைபேசிகள்',
        CAMERAS_AND_CAMCORDERS: 'கேமராக்கள் & கேம்கோடர்கள்',
        ELECTRONIC_SERVICES_AND_REPAIRS: 'இலத்திரனியல் சேவைகள் மற்றும் பழுது பார்த்தால் ',
        OTHER_ELECTRONICS: 'ஏனைய இலத்திரனியல் பொருட்கள் ',
        NEW: 'புதிய ',
        USED: 'பயன்படுத்திய ',
        BRAND_NAME: 'புத்தம் புதியது',
        MODEL: 'மாதிரி',
        COMMERCIAL_PROPERTY_FOR_SALE: 'வணிக சொத்து விற்பனைக்கு',
        HOUSES_AND_VILLAS_FOR_SALE: 'வீடுகள் மற்றும் வில்லாக்கள் விற்பனைக்கு',
        APARTMENTS_FOR_SALE: 'அடுக்குமாடி குடியிருப்புகள் விற்பனைக்கு',
        LANDS_FOR_SALE: 'நிலங்கள் விற்பனைக்கு',
        BED_ROOMS: 'படுக்கை அறைகள்',
        BATH_ROOMS: 'குளியல் அறைகள்',
        FLOOR_AREA_MIN: 'குறைந்தபட்ச தரை  பகுதி ',
        FLOOR_AREA_MAX: 'அதிகபட்சம் தரை  பகுதி',
        PARKING_SPACE: 'வாகன தரிப்பிடம் ',
        FURNISHED: 'வீட்டு உபகரணங்களுடன் ',
        LAND_SIZE_MIN: 'குறைந்தபட்ச நில அளவு',
        LAND_SIZE_MAX: 'அதிகபட்சம்  நில அளவு ',
        PRIVATE_ENTRANCE: 'தனியார் நுழைவாயில்',
        PRIVATE_BATHROOM: 'தனியார் குளியலறை',

        //filter

        APARTMENTS_FOR_RENT: 'அடுக்குமாடி குடியிருப்புகள் வாடகைக்கு',
        HOUSES_FOR_RENT: 'வீடு வாடகைக்கு',
        ROOMS_FOR_RENT: 'அறைகள் வாடகைக்கு',
        COMMERCIAL_PROPERTY_FOR_RENT: 'வணிக சொத்து வாடகைக்கு',
        'SHORT_TERM_(DAILY)': 'குறுகிய காலம் (தினசரி)',
        'SHORT_TERM_(MONTHLY)': 'குறுகிய காலம் (மாதாந்தம் )',
        LANDS_FOR_RENT: 'நிலங்கள் வாடகைக்கு',
        HOME_AND_GARDEN: 'இல்லம் மற்றும் பூந்தோட்டம்',
        KIDS_AND_BABY_ITEMS: 'மழலைகள் மற்றும் குழந்தைப் பொருட்கள் ',
        FASHION_AND_BEAUTY: 'அழகுசாதன பொருட்கள்',
        HOBBY_AND_SPORTS: 'பொழுதுபோக்கு மற்றும் விளையாட்டு',
        PETS_AND_ANIMALS: 'செல்லப்பிராணிகள் மற்றும் விலங்குகள்',
        BUSINESS_AND_INDUSTRY: 'வணிகம் மற்றும் தொழில்சர் ',
        WANTED_AND_LOOKING_FOR: 'தேவை மற்றும் தேடப்படும் ',
        LOST_AND_FOUND: 'இழந்தது & கிடைத்தது',
        SERVICES: 'சேவைகள்',
        AGRICULTURE: 'விவசாயம்',
        ESSENTIALS: 'அத்தியாவசியமானவை',
        TRAVEL_AND_LEISURE: 'பயணம் மற்றும் ஓய்வு',

        //footer
        FOOTER_WELCOME: 'நவீன இலங்கையர்களுக்காக வடிவமைக்கப்பட்ட புதிய சந்தைக்கு வரவேற்கிறோம். வாகனங்களுக்கான சிறந்த சலுகைகளைக் கண்டறியவும், சொத்து, இலத்திரனியல் மற்றும் பல பரந்த அளவிலான உயர்விலிருந்து தேர்வு செய்யவும்',

        FOOTER_DOWNLOAD_APP: 'உங்கள் வாழ்க்கையை மேம்படுத்திக்கொள்ள அப் ஐ  பதிவிறக்கம் செய்யவும் !',
        FAQ: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
        BLOG: 'வலைப்பதிவு',
        MEMBERSHIP: 'அங்கத்துவம் ',
        PRIVACY_POLICY: 'தனியுரிமைக் கொள்கை',
        TERMS_AND_CONDITIONS: 'விதிமுறைகள் மற்றும் நிபந்தனைகள் ',
        CONTACT: 'தொடர்பு',
        HOTLINE: 'துரித எண்',
        EMAIL: 'மின்னஞ்சல்',
        CONNECT_WITH_US: 'எங்களை தொடர்பு கொள்ளுங்கள்:',
        COPYRIGHTS: 'பதிப்புரிமை © 2021 Saruwata.lk - - அனைத்து உரிமைகளும் பெறப்பட்டன ',

        POST_AD: 'விளம்பரத்தை வெளியிடுக ',
        SELECT_APPROPRIATE_CATEGORY: 'உங்கள் விளம்பரத்தை வெளியிடும் போது பொருத்தமான பிரிவை தெரிவு செய்க ',
        CONTINUE: 'தொடரவும்',
        CHECK_YOUR_PHOTOS_AND_CONFIRM: 'உங்கள் புகைப்படங்களைச் சரிபார்த்து உறுதிப்படுத்தவும்',
        ADD_UPTO_10_PHOTOS: '10 புகைப்படங்கள் வரை பதிவிடலாம் - நீங்கள் குறைந்தது ஒரு புகைப்படத்தையாவது பதிவேற்ற வேண்டும்*',
        CLICK_UPLOADED_IMAGE_TO_CHANGE_TITTLE: 'முதல் படத்தை மாற்ற பதிவேற்றிய படத்தின் மீது கிளிக் செய்யவும்',
        IMAGES_FORMAT: 'படங்கள் JPG அல்லது PNG வடிவத்தில் இருக்க வேண்டும் (அதிகபட்சம் 15 MB).',
        NO_WATERMARS: 'வோட்டர்மார்க்ஸ் உடன் படங்களை பதிவேற்ற வேண்டாம்.',
        CONFIRM_IMAGE_UPLOAD: 'படப் பதிவேற்றத்தை உறுதிப்படுத்தவும்',
        DETAILS_FILLED: 'விவரங்கள் நிரப்பப்பட்டுள்ளன',
        ADD_POST_DISCARD: 'உங்கள் விளம்பரம் நிராகரிக்கப்படும்',
        CANCEL: 'ரத்து செய்',
        YES: 'ஆம்',
        IMAGE_CANNOT_CHANGE_LATER: 'நீங்கள் தேர்ந்தெடுத்த படங்களை பின்னர் மாற்ற முடியாது..!',
        CLOSE: 'மூடு ',
        OK: 'சரி',
        FILL_IN_THE_DETAILS: 'விவரங்களை நிரப்பவும்',
        IMAGES_CANNOT_CHANGE_LATER: 'நீங்கள் தேர்ந்தெடுத்த படங்களை பின்னர் மாற்ற முடியாது..!',
        CONTACT_DETAILES: 'தொடர்பு விபரங்கள்',
        ACCEPT_TERMS: 'விளம்பரத்தை வெளியீடுக என்பதை கிளிக் செய்வதன் மூலம் பயன்பாட்டு விதிமுறைகளை நீங்கள் ஏற்றுக்கொள்கிறீர்கள், பாதுகாப்பு உதவிக்குறிப்புகளைக் நீங்கள் கடைப்பிடிப்பீர்கள் என்பதை உறுதிப்படுத்துங்கள், மேலும் இந்த விளம்பரத்தில் தடைசெய்யப்பட்ட எதுவும் இல்ல என்பதை உறுதிப்படுத்துங்கள்..',
        CLEAR_ALL: 'அனைத்தையும் அழி',
        OKAY: 'சரி',
        WAIT: 'காத்திரு',
        SELECT_RIGHT_SUB_CATEGORY: 'உங்கள் சரியான துணைப்பிரிவைத் தேர்ந்தெடுக்கவும்',
        TITTLE_IMAGE: 'தலைப்பு படம்',
        NEXT: 'அடுத்தது',
        MAX_SIZE_15MB: 'படத்தின் அதிகபட்ச அளவு 15MB',
        IMAGE_COUNT_EXCEED: 'பட எண்ணிக்கை அதிகரித்துள்ளது ',
        MAX_IMAGE_COUNT_EXCEED_IN_FIRST_10: 'படைகளின் எண்ணிக்கை அதிகரித்துள்ளது முதல் 10 படங்கள் பயன்படுத்தப்படும்',
        CHANGE_COVER_IMAGE_FIRST: 'பிரதான படத்தை முதலில் மாற்றவும்',
        CONTINUE_IMAGE_PROCESSING: 'பட செயலாக்கத்தைத் தொடரவும்',
        ASK_FOR_PRICE: 'விலையை கேளுங்கள்',
        MAX_72_CHARACTERS: 'அதிகபட்சம் 72 எழுத்துகள்',
        MAX_4000_CHARACTERS: 'அதிகபட்சம் 4000 எழுத்துகள்',
        PER_TOTAL: '/ மொத்தம்',
        PER_PERCH: '/ ஒரு பேர்ச் ',
        PER_ACRES: '/ ஏக்கருக்கு',
        PERCHES: 'பெர்ச்கள்',
        PER_MONTHLY: ' / ஒரு மாதத்திற்கு',
        MOBILE_NUMBER: 'தொலைபேசி இலக்கம்',
        WHATSAPP_NUMBER: 'வாட்ஸ்அப் எண்',
        WHATSAPP: 'வாட்ஸ்அப்',
        USE_SAME_NUMBER_FOR_WHATSAPP: 'அதே எண்ணை WhatsApp க்கும் பயன்படுத்தவும்',
        PROFILE_IMAGE: 'ப்ரொபைல் படம் ',
        HELLO: 'வணக்கம்,',
        WHAT_ARE_YOU_LISTING_TODAY: 'இன்று என்ன பட்டியலிடுகிறீர்கள்?',
        LISTING_SUMMARY: 'பட்டியல் சுருக்கம்',
        PHOTOS_ADDED: 'புகைப்படங்கள் சேர்க்கப்பட்டது',
        PHOTOS_ADDED_1: 'புகைப்படங்கள்',
        PHOTOS_ADDED_2: 'சேர்க்கும்',
        TITTLE: 'தலைப்பு',
        DESCRIPTION: 'விளக்கம்',
        PRICE: 'விலை',

        DISTRICT: 'மாவட்டங்கள்',
        CITY: 'நகரம்',
        MILEAGE: 'தூரம்',
        RS: 'ரூ',
        FLOOR_AREA_SQFT: 'தரை அளவு (சதுர அடி)',
        LAND_SIZE_ACRES: 'நில அளவு (ஏக்கர்)',
        LAND_SIZE_PERCHES: 'நில அளவு (பேர்ச்)',
        LAND_SIZE: 'நில அளவு',
        TOTAL: 'மொத்த',
        PER_PERC: 'பெர்ச் ஒன்றுக்கு',
        PER_ACRE: 'ஏக்கருக்கு',
        UNIT: 'அலகு',
        PROPERTY: 'சொத்து',
        BRAND: 'வர்த்தகக் குறி',
        MEMORY_CAPACITY: 'நினைவக திறன்',
        MEMORY: 'நினைவக திறன்',
        FLOOR_AREA: 'தரை அளவு ',
        BACK: 'மீண்டும்',
        ALL_STEP_COMPLETED: 'அனைத்து படிகளும் முடிந்தது - விளம்பரங்களுக்கு தொடரவும்',
        BACK_TO_SELECTED_CATEGORY: 'தேர்ந்தெடுக்கப்பட்ட வகைக்குத் திரும்பு',
        HOME: 'Home',
        EDIT_PROFILE: 'சுயவிவரத்தைத் திருத்து',
        MY_ADS: 'எனது விளம்பரங்கள்',
        MY_MEMBERSHIP: 'எனது அங்கத்துவம்',
        SHOP_PAGE_SETTING: 'கடை பக்க அமைப்புகள்',
        FAVORITES: 'பிடித்தவை',
        EMAIL_ADDRESS: 'மின்னஞ்சல் முகவரி',
        PASSWORD: 'கடவுச்சொல்',
        LOG_IN: 'உள்நுழைய',
        HOW_TO_SELL: 'எப்படி விற்பனை செய்வது',
        AD_PROMOTION: 'விளம்பர விளம்பரங்கள்',
        CONTACT_US: 'எங்களை தொடர்பு கொள்ள',
        LOG_OUT: 'கணக்கிலிருந்து வெளியேறு',
        LIKES: 'விரும்புகிறது',
        VIEWS: 'காட்சிகள்',
        POSTED_BY: 'இடுகையிட்டது',
        SEE_ALL_ADS: 'அவருடைய எல்லா விளம்பரங்களையும் பார்க்கவும்',
        CLICK_TO_SHOW_PHONE: 'தொலைபேசி எண்ணைப் பார்க்க கிளிக் செய்யவும்',
        CHOOSE_LOCATION:'இருப்பிடத்தைத் தேர்ந்தெடுக்கவும்',


        //membership
        INCREASE_YOUR_SALES: 'Saruwata.lk  அங்கத்தவராவதன் மூலம் உங்கள் விற்பனையை அதிகரிக்கவும்!',
        MEMEBRSHIP_PROVIDE: 'அங்கத்துவங்கள் உங்கள் நிறுவனத்திற்கு Saruwata.lk இல் வலுவான சுயவிவரத்தை வழங்குகின்றன,  மேலும் பலரைச் சென்றடைய உங்களை அனுமதிக்கிறது.',
        AS_A_MEMBER: "உறுப்பினராக, Saruwata.lk இல் உங்கள் வணிகத்தை எவ்வாறு மேம்படுத்துவது என்பது குறித்த மதிப்புமிக்க ஆலோசனைகளைக் கொண்ட எங்கள் செய்திமடலையும் நீங்கள் பெறுவீர்கள்.",
        BECOME_A_MEMBER: 'உறுப்பினராவதற்கு',
        BENEFITS_OF_MEMBERSHIP: 'உறுப்பினராவதன் அனுகூலங்கள்',
        STEP_UP_YOUR_SALES: 'உறுப்பினர் சேவைகள் மூலம் உங்கள் விற்பனையை அதிகரிப்பதுடன் பணத்தை எளிதாக  பெறுங்கள்!',
        POST_MORE_ADS: 'மேலும் விளம்பரங்களை இடுகையிடவும்',
        POST_MORE_ADS_DESC: "உறுப்பினராவதன் மூலம், நீங்கள் அதிக விளம்பரங்களை இடுகையிடலாம்! விளம்பரக் கட்டுப்பாடுகளைப் பற்றி கவலைப்படுவதற்குப் பதிலாக, உங்கள் தயாரிப்புகளை திறமையாக விற்பனை செய்வதற்கான நல்ல விளம்பரங்களை வடிவமைப்பதில் கவனம் செலுத்துங்கள். நீங்கள் அதிக விளம்பரங்களை வெளியிட்டால் விற்பனை பெருகும்!",
        FREE_PROMOTION_VOUCHERS: 'இலவச விளம்பர வவுச்சர்கள்',
        FREE_PROMOTION_VOUCHERS_DESC: "ஒவ்வொரு மாதமும், உங்கள் விளம்பரங்களில் Super Ad, Jump Ad, Quick Sell அல்லது பிரத்யேக விளம்பரங்களைச் சேர்க்க நீங்கள் பயன்படுத்தக்கூடிய இலவச வவுச்சர்களைப் பெறுவீர்கள் மேலும் அதிக பதில்களைப் பெறுவீர்கள்.",
        RECOGNIZE_AS_A_MEMBER: "உறுப்பினராக அங்கீகாரம்",
        RECOGNIZE_AS_A_MEMBER_DESC: 'உறுப்பினர் பெட்ஜ் அனைத்து உறுப்பினர் விளம்பரங்களிலும் தோன்றும், தனிப்பட்ட விற்பனையாளர்களால் இடுகையிடப்பட்ட விளம்பரங்களிலிருந்து உங்கள் விளம்பரங்களை வேறுபடுத்தி காட்டும். உங்கள் விளம்பரங்களை மேலும் தனித்துவமாக்க சிறப்பு பேட்ஜ்களையும் நாம் வழங்குகிறோம்.',
        MEMBER_SHOP: 'வியாபாரத் தளம்',
        MEMBER_SHOP_DESC: 'உங்கள் நிறுவனத்திற்கு முழுப் பக்கத்தையும் அர்ப்பணிப்பதன் மூலம் saruwata. lk இல் உங்கள் சொந்த வியாபாரப் குறியை உருவாக்குங்கள். உங்களின் அனைத்து விளம்பரங்களும் ஒரே இடத்தில் பட்டியலிடப்பட்டுள்ள உங்களின் சொந்த ஒன்லைன் வியாபாரத் தளத்தை போன்றே இது, உங்கள் நாமம், குறியீடு, அட்டைப் படம் மற்றும் பிற வணிகத் தகவலைச் சேர்ப்பதன் மூலம் அதைத் தனிப்பயனாக்கலாம்.',
        TRACK_YOUR_AD: 'உங்கள் விளம்பரத்தைக் கண்காணிக்கவும்',
        TRACK_YOUR_AD_DESC: 'தினசரி அடிப்படையில், உங்கள் விளம்பரம் வாடிக்கையாளர்களிடையே எவ்வளவு ஆர்வத்தை உருவாக்கியுள்ளது என்பதைப் பார்க்கவும் விளம்பரத்தின் செயல்திறனைப் புரிந்து கொள்ளவதுடன்,  பார்வைகளின் எண்ணிக்கையையும் கண்காணிக்கலாம்.',
        DEDICATE_ACCOUNT_MANAGER: 'கணக்கு நிர்வாகி உதவி',
        DEDICATE_ACCOUNT_MANAGER_DESC: 'விளம்பர இடுகை உட்பட உங்களின் அனைத்துத் தேவைகளுக்கும் உதவ தனிப்பட்ட நிர்வாகியைப் பெறுங்கள். உங்கள் அங்கத்துவக்காலம் முழுவதும், நீங்கள் எங்கள் விற்பனை மற்றும் வாடிக்கையாளர் ஆதரவு குழுக்களுக்கான அணுகலைப் பெறுவீர்கள்.',
        ADVERTISE_YOUR_PRODUCTS: 'Saruwata.lk இப்போதே',
        AND_SERVICES_ON: 'உங்கள் பொருட்கள் மற்றும்',
        SARUWATA_LK_NOW: 'சேவைகளை விளம்பரப்படுத்துங்கள்!',
        WE_WILL_HELP_YOU: 'Saruwata.lk இல் உங்கள் வியாபார தளத்தை உருவாக்கவும் உங்கள் தயாரிப்புகளை பட்டியலிடவும் நாங்கள் உதவுவோம்.',
        REQUEST_A_CALL_BACK: 'இப்போது மீண்டும் அழைப்பைக் கோருங்கள்!',
        LET_US_HELP: 'நாங்கள் உங்களுக்கு உதவுவோம்!',
        YOUR_NAME: 'உங்கள் பெயர்',
        PHONE_NUMBER: 'தொலைபேசி எண்',
        YOUR_EMAIL: 'உங்கள் மின்னஞ்சல்',
        SELECT_CATEGORY: 'பிரிவை தேர்வு செய்க',
        BUSINESS_LOCATION: 'வணிக இடம்',
        SUBMIT: 'சமர்ப்பிக்கவும்',

    };

    get getLan() {
        if (this.selectedLan == 'english') {
            return this.english;

        } else if (this.selectedLan == 'sinhala') {

            return this.sinhala;
        }
        else if (this.selectedLan == 'tamil') {

            return this.tamil;
        }
    }



};

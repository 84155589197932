import { IonIcon } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import KeywordSearch from "../../../components/KeywordSearch/KeywordSearch";
import PostCard from "../../../components/Post/PostCardFixed/PostCard";
import { setCurrentLocation } from "../../../features/navigation/navigationSlice";
import { Post } from "../../../features/post/Post";
import {
  clearSearchResult,
  saveSortOption,
  searchResultByTags,
  selectSearcedPosts,
  selectSerpPage,
  selectSortOption,
  setSerpPage,
} from "../../../features/search/searchSlice";
import { NAV_SEARCH_RESULT } from "../../../providers/NavigationProvider";
import "./SearchResult.css";
import {
  Box,
  Button,
  Chip,
  createStyles,
  Fab,
  FormControl,
  Grid,
  Hidden,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "@material-ui/lab/Alert";
import PulseLoader from "react-spinners/PulseLoader";
import CloseIcon from "@material-ui/icons/Close";
import SubCategory from "../../../components/AddPost/SubCategory/SubCategory";
import { selectPosition } from "../../../features/scroll/scrollSlice";
import { ArrowUpward } from "@material-ui/icons";
import ScrollRestore from "../../../components/ScrollRestore";
import { logEvent } from "firebase/analytics"
import { ACTION_INPUT, ACTION_VIEW } from "../../../config/actions";
import {
  KEYWORD_SEARCH_WITH_TAG,
  VIEW_SEARCH_RESULTS,
} from "../../../config/events";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Spotlight from "../../../components/Post/SpotlightAd/Spotlight";
import { selectFiltersss } from "../../../features/search/FilterSlice";
import { filter } from "ionicons/icons";
import PostCardMobile from "../../../components/Post/PostCardFixed/PostCardMobile/PostCardMobile";
import KeywordSearchMobile from "../../../components/KeywordSearch/KeywordSearchMobile";
import { selectLanguage } from "../../../features/language/language_slice";
import { Language } from "../../../language/Language";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { analytics } from "../../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagline: {
      fontWeight: 700,
      paddingBottom: 7,
      paddingLeft: 5,
      paddingRight: 5,
      textTransform: "capitalize",
      // paddingTop: "12px"
    },
    btnApply: {
      "&:hover": {
        boxShadow: "0px 0px 8px #00000026",
      },
    },
    select: {
      backgroundColor: "#fff",
      width: "170px",
      height: "39px",
      borderRadius: "3px",
      borderBottom: "none !important",
      fontSize: "14px !important",
      color: "#5A5A5A !important",
      paddingLeft: "11px",
    },
  })
);

const SearchResult = (props: any) => {

  const results: any[] = useSelector(selectSearcedPosts);
  const selectedLanguage = useSelector(selectLanguage);
  const getLanguage = (): string => {
    if (selectedLanguage == 'en') {
      return 'english';
    } else if (selectedLanguage == 'si') {
      return 'sinhala';
    } else if (selectedLanguage == 'ta') {
      return 'tamil';
    } else {
      return 'english';
    }
  }

  // var lan = require('../../../language/' + getLanguage());
  var l = new Language(getLanguage());
  var lan = l.getLan as any;

  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loc = useLocation()
  const sortOrder = useSelector(selectSortOption);
  const [sort, setSort] = React.useState("chronological");
  const [noResult, setNoResult] = useState(false);
  const [hasResult, setHasResult] = useState(true);
  const topref = useRef<any>();
  const [query, setQuery] = useState("");
  const [c1, setC1] = useState("");
  const [c2, setC2] = useState("");
  const [tags, setTags] = useState("");
  const [filters, setFilters] = useState("");
  const serpPage = useSelector(selectSerpPage);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const classes = useStyles();
  const [busy, setbusy] = useState(false);
  const [showScrollTop, setshowScrollTop] = useState(false);
  const scrollPosition = useSelector(selectPosition);
  const scrollContent = document.getElementById("scrollable-root");

  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.up("lg"));
  const filterTest = useSelector(selectFiltersss);
  const navigationType = useNavigationType();


  useEffect(() => {
    setshowScrollTop(scrollPosition > 2000);
    if (scrollPosition == 0) handleScrollToTopRequest();
  }, [scrollPosition]);

  useEffect(() => {
    dispatch(setCurrentLocation(NAV_SEARCH_RESULT));
    logEvent(analytics, VIEW_SEARCH_RESULTS, { action: ACTION_VIEW });
    const url = new URLSearchParams(window.location.search);
    setQuery(
      url
        .get("phrase")
        ?.replace(/[^\w\s]/g, "")
        .replace(/\s\s+/g, " ")
        .trim() || ""
    );
    setC1(
      url
        .get("c1")
        ?.replace(/[^\w\s]/g, "")
        .replace(/\s\s+/g, " ")
        .trim() || ""
    );
    setC2(
      url
        .get("c2")
        ?.replace(/[^\w\(\)\s\&]/g, "")
        .replace(/\s\s+/g, " ")
        .trim() || ""
    );
    setTags(url.get("tags")?.trim() || "");
    setFilters(url.get("filters")?.trim() || "");
  }, [window.location.search]);

  useEffect(() => {
    // if (results.length <= 0 || navigation.formAction === "PUSH") doSearch();

    if (results.length <= 0 || navigationType=="PUSH" ) {
      doSearch();
    }
    else setPage(serpPage);
    setSelectedTags(tags ? tags.split(",") : []);
  }, [query, c1, c2, tags, filters]);

  useEffect(() => {
    dispatch(setSerpPage(page));

  }, [page]);

  const doSearch = () => {
    if (
      (query == "" || query == null) &&
      (c1 == "" || c1 == null) &&
      (c2 == "" || c2 == null) &&
      (tags == "" || tags == null) &&
      (filters == "" || filters == null)
    ) {
      return;
    }
    setNoResult(false);
    dispatch(
      searchResultByTags(
        query,
        prepareTags(),
        0,
        filters,
        sort,
        () => setNoResult(true),
        () => setHasResult(true),
        filterTest
      )
    );
    //scroll to top
  };

  const prepareTags = (): string => {
    return (c1 ? `${c1},` : "") + (c2 ? `${c2},` : "") + tags.toLowerCase();
  };

  const handleSort = (event: React.ChangeEvent<{ value: unknown }>) => {

    setSort(event.target.value as string);
    handleSortChange(event.target.value as string);
  };

  const loadMorePosts = () => {
    setbusy(true);
    dispatch(
      searchResultByTags(
        query,
        prepareTags(),
        page + 1,
        filters,
        sort,
        () => {
          setHasResult(false);
        },
        () => {
          setHasResult(true);
          setbusy(false);
        },
        filterTest
      )
    );
    setPage(page + 1);
  };

  const handleKeywordSelect = (val: string) => {
    setPage(0);
    if (val)
      navigate(
        `/search?phrase=${encodeURIComponent(val)}${c1 ? `&c1=${c1}` : ""}${c2 ? `&c2=${encodeURIComponent(c2)}` : ""
        }`
      );
    logEvent(analytics, KEYWORD_SEARCH_WITH_TAG, {
      action: ACTION_INPUT,
      data: c2,
      platform: "DESKTOP",
    });
  };

  const handleSortChange = (sort: string) => {
    if (sort != sortOrder) {
      dispatch(saveSortOption(sort));
      dispatch(clearSearchResult({}));
      dispatch(
        searchResultByTags(
          query,
          prepareTags(),
          0,
          filters,
          sort,
          () => setNoResult(true),
          () => setHasResult(true),
          filterTest
        )
      );
      setPage(0);
    }
  };

  const onSelectedTagRemove = (name: string) => {
    const ftags = selectedTags.filter((st) => st !== name).join(",");
    navigate(
      `/search?${query ? `phrase=${query}` : ""}${c1 ? `&c1=${c1}` : ""}${c2 ? `&c2=${c2}` : ""
      }${ftags ? `&tags=${ftags}` : ""}${filters ? `&filters=${filters}` : ""}`
    );
  };

  const buildTag = (name: string) => {
    return (
      <Chip
        size="small"
        icon={<CloseIcon color="primary" />}
        label={name}
        onClick={() => onSelectedTagRemove(name)}
        key={name}
        style={{
          background: "white",
          border: "1px solid #00C3FF",
          color: "black",
          margin: "5px 5px",
          height: "24px",
        }}
        color="primary"
      />
    );
  };

  const renderTagline = () => {
    return query
      ? `Search result for '${query}'`
      : c2
        ? c2 + " in Sri Lanka"
        : c1 + " in Sri Lanka";
  };

  const handleScrollToTopRequest = () => {
    setshowScrollTop(false);
    scrollContent?.scrollTo(0, 0);
  };
  const loadSpotLight = () => {

    return (
      <Spotlight />

    );
  };



  const isMotor = () => {
    return c1 === "motors";

  };

  const isProperty = () => {
    return c1 === "property for sale";
  };
  const isPropertyRent = () => {
    return c1 === "property for rent";
  };
  const isElectronic = () => {
    return c1 === "mobiles and electronics";
  };
  const isClassified = () => {
    return c1 === "classifieds";
  };



  return (
    <div ref={topref}>
      <Hidden mdDown={true}>
        <div className={classes.tagline}>{renderTagline()}</div>
      </Hidden>
      <Grid container >
        <Hidden xsDown={true} smDown={true}>
          <Grid
            item
            xs={12}
            id="search-tabbar"

          // style={{ maxWidth: `${!smallViewPort && "unset"}` }}
          >
            <div>
              <KeywordSearch
                onSearchEnter={handleKeywordSelect}
                term={query}
              ></KeywordSearch>

            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div id="txt-sortby">
                {/* Sort by */}
                {lan.SORT_BY}
              </div>
              <img
                src="assets/icon/icon-sort.svg"
                style={{ marginRight: "10px" }}
              ></img>
              <div id="sort-box">
                <FormControl>
                  <Select
                    value={sort}
                    onChange={handleSort}
                    displayEmpty
                    className={classes.select}
                  >
                    <MenuItem value="price_low_to_high">
                      {/* Price: Low */}
                      {lan.PRICE_LOW}
                    </MenuItem>
                    <MenuItem value="price_high_to_low">
                      {/* Price: High */}
                      {lan.PRICE_HIGH}
                    </MenuItem>
                    <MenuItem value="chronological">
                      {/* Date: Newest on top */}
                      {lan.NEWEST_TO_TOP}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
        </Hidden>
        <Hidden lgUp={true} mdUp={true}>
          <Grid item xs={12} id="search-tabbar-mobile" style={{ padding: "5px 5px", marginTop: "5px", marginBottom: "5px" }} >
            <KeywordSearchMobile
              onSearchEnter={handleKeywordSelect}
              term={query}
            ></KeywordSearchMobile>
          </Grid>
        </Hidden>



        <div style={{ height: "5px", width: "100%" }}></div>
        <Grid item xs={12}>
          {selectedTags.map((tag: any) => buildTag(tag))}
        </Grid>
      </Grid>
      <div style={{ height: "10px" }}></div>

      {loadSpotLight()}

      <div style={{ height: "10px" }}></div>
      {results.length > 0 ? (
        <>
          <InfiniteScroll
            hasMore={hasResult}
            style={{ overflowX: 'hidden' }}
            loader={
              <Box display="flex" justifyContent="center">
                {busy ? <PulseLoader color="#0080ff" loading={true} /> : null}
              </Box>
            }
            next={() => { }}
            dataLength={results.length}
            scrollableTarget="scrollable-root"
            endMessage={
              <Alert severity="success" color="info">
                {/* You have seen all */}
                {lan.YOU_HAVE_SEEN_ALL}
              </Alert>
            }
          >

            <Hidden xsDown={true} smDown={true}>
              {results.map((item: Post, index: number) => (
                <>
                  <div key={item.id} style={{ padding: "1px 5px" }}>
                    <LazyLoadComponent
                      placeholder={
                        <img
                          src="assets/images/Place-holder-listing.png"
                          style={{ width: "707px", height: "194px" }}
                        ></img>
                      }
                      key={item.id}
                    >
                      <PostCard
                        post={item}
                        position={index + 1}
                        detailsAction={() => { }}
                      ></PostCard>

                    </LazyLoadComponent>
                  </div>

                  {
                    (index > 1 ?

                      (index % 14 == 0 ?
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            style={{
                              marginTop: "30px",
                              marginBottom: "30px"
                            }}

                          >

                            {isMotor() ? (

                              <iframe scrolling="no"
                                style={{ width: "728px", height: "90px", overflowX: "hidden", border: "none" }}
                                src="https://services.saruwata.lk/AdManager/L1/L1-Motors-Desktop-Listing-15-Leaderboard-728x90/index.html" />

                            ) : isProperty() ? (

                              <iframe scrolling="no"
                                style={{ width: "728px", height: "90px", overflowX: "hidden", border: "none" }}
                                src="https://services.saruwata.lk/AdManager/L1/L1-Property-Sale-Desktop-Listing-15-Leaderboard-728x90/index.html" />


                            ) : isPropertyRent() ?


                              <iframe scrolling="no"
                                style={{ width: "728px", height: "90px", overflowX: "hidden", border: "none" }}
                                src="https://services.saruwata.lk/AdManager/L1/L1-Property-Rent-Desktop-Listing-15-Leaderboard-728x90/index.html" />

                              : isElectronic() ?

                                <iframe scrolling="no"
                                  style={{ width: "728px", height: "90px", overflowX: "hidden", border: "none" }}
                                  src="https://services.saruwata.lk/AdManager/L1/L1-Mobiles-and-Electronics-Desktop-Listing-15-Leaderboard-728x90/index.html" />


                                : isClassified() ?


                                  <iframe scrolling="no"
                                    style={{ width: "728px", height: "90px", overflowX: "hidden", border: "none" }}
                                    src="https://services.saruwata.lk/AdManager/L1/L1-Classifieds-Desktop-Listing-15-Leaderboard-728x90/index.html" />

                                  : (


                                    <iframe scrolling="no"
                                      style={{ width: "728px", height: "90px", overflowX: "hidden", border: "none" }}
                                      src="https://services.saruwata.lk/AdManager/L1/AL-All-Ads-Desktop-Listing-15-Leaderboard-728x90/index.html" />




                                  )}

                          </Grid>
                        </Grid> : null)
                      : null)
                  }
                </>
              ))}
            </Hidden>


            <Hidden lgUp={true} mdUp={true}>
              {results.map((item: Post, index: number) => (
                <>
                  <div key={item.id} style={{ padding: "5px 0px" }}>
                    <LazyLoadComponent
                      placeholder={
                        <img
                          src="assets/images/Place-holder-listing.png"
                          style={{ width: "auto", height: "100px" }}
                        ></img>
                      }
                      key={item.id}
                    >
                      <PostCardMobile
                        post={item}
                        position={index + 1}
                        detailsAction={() => { }}
                      ></PostCardMobile>
                    </LazyLoadComponent>
                  </div>

                  {
                    (index > 1 ?

                      (index % 14 == 0 ?
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            style={{
                              marginTop: "30px",
                              marginBottom: "30px",
                              display: "flex",
                              justifyContent: "center"
                            }}

                          >

                            {isMotor() ? (

                              <iframe scrolling="no"
                                style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                                // src="https://services.saruwata.lk/AdManager/L1/L1-Motors-Mobile-Listing-15-Medium-Rectangle-300x250/index.html" 
                                src="https://services.saruwata.lk/AdManager/Motors/D-L1/Motors-D-L1-1st-Medium-Rectangle-300x250/index.html" 
                                
                                />

                            ) : isProperty() ? (

                              <iframe scrolling="no"
                                style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                                // src="https://services.saruwata.lk/AdManager/L1/L1-Property-Sale-Mobile-Listing-15-Medium-Rectangle-300x250/index.html" 
                                src="https://services.saruwata.lk/AdManager/Property-for-Sale/D-L1/Property-for-Sale-D-L1-1st-Medium-Rectangle-300x250/index.html" 
                                />


                            ) : isPropertyRent() ?


                              <iframe scrolling="no"
                                style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                                // src="https://services.saruwata.lk/AdManager/L1/L1-Property-Rent-Mobile-Listing-15-Medium-Rectangle-300x250/index.html" 
                                src="https://services.saruwata.lk/AdManager/Property-for-Rent/D-L1/Property-for-Rent-D-L1-1st-Medium-Rectangle-300x250/index.html" 
                                />
                                
                                : isElectronic() ?
                                
                                <iframe scrolling="no"
                                style={{ width: "300px", height: "250px", overflowX: "hidden", border: "none" }}
                                // src="https://services.saruwata.lk/AdManager/L1/L1-Mobiles-and-Electronics-Mobile-Listing-15-Medium-Rectangle-300x250/index.html" 
                                src="https://services.saruwata.lk/AdManager/Mobiles-and-Electronics/D-L1/Mobiles-and-Electronics-D-L1-1st-Medium-Rectangle-300x250/index.html" 
                                
                                />
                                
                                
                                : isClassified() ?
                                
                                
                                <iframe scrolling="no"
                                style={{ width: "728px", height: "90px", overflowX: "hidden", border: "none" }}
                                // src="https://services.saruwata.lk/AdManager/L1/L1-Classifieds-Desktop-Listing-15-Leaderboard-728x90/index.html"
                                src="https://services.saruwata.lk/AdManager/Mobiles-and-Electronics/D-L1/Mobiles-and-Electronics-D-L1-1st-Medium-Rectangle-300x250/index.html" 
                                     
                                    />

                                  : (


                                    <iframe scrolling="no"

                                      style={{ width: "728px", height: "90px", overflowX: "hidden", border: "none" }}
                                      // src="https://services.saruwata.lk/AdManager/L1/L1-Classifieds-Mobile-Listing-15-Medium-Rectangle-300x250/index.html" 
                                      src="https://services.saruwata.lk/AdManager/Mobiles-and-Electronics/D-L1/Mobiles-and-Electronics-D-L1-1st-Medium-Rectangle-300x250/index.html" 
                              
                                      
                                      />




                                  )}

                          </Grid>
                        </Grid> : null)
                      : null)
                  }

                </>
              ))}
            </Hidden>


            {/* 
            {results.map((item: Post, index: number) => (
              <div key={item.id} style={{ padding: "10px 5px" }}>
                <LazyLoadComponent
                  placeholder={
                    <img src="assets/images/Place-holder-listing.png"></img>
                  }
                  key={item.id}
                >
                  <PostCard
                    post={item}
                    position={index + 1}
                    detailsAction={() => { }}
                  ></PostCard>
                </LazyLoadComponent>
              </div>
            ))} */}
          </InfiniteScroll>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px"

              }}
            >
              {busy ? null : (
                <Button
                  color="primary"
                  id="btn-load-more"
                  onClick={loadMorePosts}
                  className={classes.btnApply}
                  style={{ fontSize: (getLanguage() == 'sinhala' ? "13px" : "16px") }}
                >
                  <span>
                    {/* Load More */}
                    {lan.LOAD_MORE}
                  </span>
                </Button>
              )}
            </Grid>
          </Grid>
          {page > 0 ? (
            <div
              style={{
                position: "sticky",
                bottom: "100px",
                zIndex: 999,
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                right: "10px",
                pointerEvents: "none",
              }}
            >
              {showScrollTop ? (
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={handleScrollToTopRequest}
                  style={{ pointerEvents: "all" }}
                >
                  <ArrowUpward></ArrowUpward>
                </Fab>
              ) : null}
            </div>
          ) : null}
        </>
      ) : noResult ? (
        <div className="no-text">
          <IonIcon
            src="assets/images/no-ads-desktop.svg"
            className="cactus"
          ></IonIcon>
          <div>Be More General</div>
          <div>I'm still learning</div>
        </div>
      ) : null}



      {/* <ScrollRestore {...props} /> */}
    </div>
  );
};

export default SearchResult;

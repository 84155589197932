import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setBusy } from "../navigation/navigationSlice";
import { onSignOut } from "./sessionSlice";
import { getAuth, onAuthStateChanged, signInWithPopup, signInWithRedirect, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../..";


// Define a type for the initial state
interface FirebaseState {
  user: string | null;
}

const initialState: FirebaseState = {
  user: null
};

export const slice = createSlice({
  name: 'firebase',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<string | null>) => {
      state.user = action.payload;
    }
  },
});

export const { setCurrentUser } = slice.actions;

// Authentication functions with TypeScript

export const signInWithGoogle = (callback: (user: any, token: string) => void, errorCallback: (error: string) => void) => async (dispatch: any) => {
  dispatch(setBusy(true));
  const provider = new GoogleAuthProvider();
  provider.addScope('profile');

  try {
    const userCredential = await signInWithPopup(auth, provider);
    const user = userCredential.user;
    const token = await user.getIdTokenResult(true);
    callback(user, token.token);
  } catch (error) {
    errorCallback(error.message);
  } finally {
    dispatch(setBusy(false));
  }
};

export const signInWithFacebook = () => async (dispatch: any) => {
  dispatch(setBusy(true));
  const provider = new FacebookAuthProvider();
  provider.addScope('public_profile');
  provider.addScope('user_birthday');
  provider.addScope('user_gender');

  try {
    await signInWithRedirect(auth, provider);
  } catch (error) {
    // console.log(error.message);
  } finally {
    dispatch(setBusy(false));
  }
};

export const signInWithEmailPassword = (email: string, password: string, callback: (user: any, token: string) => void, msg: (message: string) => void) => async (dispatch: any) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const token = await user.getIdTokenResult(true);
    
    callback(user, token.token);
  } catch (error) {
    msg(error.message);
  }
};

export const createUserWithEmailPassword = (email: string, password: string, callback: (user: any) => void, msg: (message: string) => void) => async (dispatch: any) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    callback(user);
  } catch (error) {
    msg(error.message);
  }
};

export const forgotPassword = (email: string, callback: (message: string) => void, msg: (message: string) => void) => async (dispatch: any) => {
  try {
    await sendPasswordResetEmail(auth, email);
    callback('Password Reset Email Sent');
  } catch (error) {
    // msg(error.message);
  }
};

export const signOut = (callback: () => void) => async (dispatch: any) => {
  dispatch(setBusy(true));
  // cfaSignOut().subscribe(async () => {
  try {
    await auth.signOut();
    dispatch(onSignOut([]));
    callback();
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(setBusy(false));
  }
  // });
};

export const checkUserState = (hasUser: (user: any) => void, noUser: () => void) => (dispatch: any) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      hasUser(user);
      dispatch(setCurrentUser(user.email));
    } else {
      noUser();
      dispatch(setCurrentUser(null));
    }
  });
};

export const selectCurrentFBUser = (state: any) => state.firebase.user;

export default slice.reducer;

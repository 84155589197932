import React, { useEffect, useState } from "react";
import { logEvent } from "firebase/analytics"
import { ACTION_CLICK } from "../../config/actions";
import { CLICK_ON_DETAIL_PAGE_BANNER } from "../../config/events";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { analytics } from "../..";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    root: {
      [theme.breakpoints.up("md")]: {
        display: 'block'
      }, [theme.breakpoints.down("md")]: {
        display: 'none',

      }
    }
    ,

  })
);
const DetailPageBanner = () => {

  const classes = useStyles();
  const location = useLocation();
  const [c1, setC1] = useState("");

  useEffect(() => {

    setC1(location.pathname.split("/")[1]);

  }, [window.location.pathname]);

  const isMotor = () => {
    return c1 === "motors";

  };

  const isProperty = () => {
    return c1 === "property for sale";
  };
  const isPropertyRent = () => {
    return c1 === "property for rent";
  };
  const isElectronic = () => {
    return c1 === "mobiles and electronics";
  };
  const isClassified = () => {
    return c1 === "classifieds";
  };


  const handleBannerClick = () => {
    logEvent(analytics, CLICK_ON_DETAIL_PAGE_BANNER, {
      action: ACTION_CLICK,
      platform: "DESKTOP",
    });
  };

  return (
    <div
      className={classes.root}
      style={{
        height: "92px",
        width: "100%",
        marginTop: "20px",
        // marginBottom: "20px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: '#FFF'
      }}
      onClick={handleBannerClick}
    >
      {/* <a
        // href="https://www.araliyalands.com/?source=saruwata.lk"
        href="https://services.saruwata.lk/advertisement/araliya/araliya-ingiriya.html"
        target="_blank"
      >
        <img
          src="assets/walls/Araliya-Lands-970x90.jpg"
          style={{ width: "971px", height: "92px" }}
          alt=""
        ></img>
      </a> */}


      {isMotor() ? (

        <iframe scrolling="no"
          style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
          // src="https://services.saruwata.lk/AdManager/DV/DV-Motors-Desktop-Top-Large-Leaderboard-970x90/index.html" 
          src="https://services.saruwata.lk/AdManager/Motors/D-DV/Motors-D-DV-Large-Leaderboard-970x90/index.html" 
          
          
          />

      ) : isProperty() ? (

        <iframe scrolling="no"
          style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
          // src="https://services.saruwata.lk/AdManager/DV/DV-Property-Sale-Desktop-Top-Large-Leaderboard-970x90/index.html" 
           src="https://services.saruwata.lk/AdManager/Property-for-Sale/D-DV/Property-for-Sale-D-DV-Large-Leaderboard-970x90/index.html"
          
          />


      ) : isPropertyRent() ?


        <iframe scrolling="no"
          style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
          // src="https://   /AdManager/DV/DV-Property-Rent-Desktop-Top-Large-Leaderboard-970x90/index.html" 
           src="https://services.saruwata.lk/AdManager/Property-for-Rent/D-DV/Property-for-Rent-D-DV-Large-Leaderboard-970x90/index.html"
           />
           
           : isElectronic() ?
           
           <iframe scrolling="no"
           style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
           // src="https://services.saruwata.lk/AdManager/DV/DV-Mobiles-and-Electronics-Desktop-Top-Large-Leaderboard-970x90/index.html" 
           src="https://services.saruwata.lk/AdManager/Mobiles-and-Electronics/D-DV/Mobiles-and-Electronics-D-DV-Large-Leaderboard-970x90/index.html"
            
            />


          : isClassified() ?


            <iframe scrolling="no"
              style={{ width: "970px", height: "90px", overflowX: "hidden", border: "none" }}
              // src="https://services.saruwata.lk/AdManager/DV/DV-Classifieds-Desktop-Top-Large-Leaderboard-970x90/index.html"
              src="https://services.saruwata.lk/AdManager/Mobiles-and-Electronics/D-DV/Mobiles-and-Electronics-D-DV-Large-Leaderboard-970x90/index.html"
         
              />

            : null}









    </div>
  );
};

export default DetailPageBanner;
